const adDimensions = {
	'appboard': [[320, 50]],
	'leaderboard': [
		[728, 90],
		[320, 50]
	],
	'superleaderboard': [
		[970, 90],
		[728, 90],
		[320, 50]
	],
	'billboard': [
		[970, 250],
		[728, 90],
		[320, 50]
	],
	'rectangle': [[320, 50]],
	'article_custom': [
		[728, 400],
		[320, 50]
	],
	'test_article_custom': [
		[728, 400],
		[468, 60],
		[320, 50]
	]
};

const adSizeMapping = {
	'appboard': [
		[
			[0, 0],
			[320, 50]
		]
	],
	'leaderboard': [
		[
			[1024, 0],
			[970, 250]
		],
		[
			[480, 0],
			[320, 50]
		],
		[
			[0, 0],
			[320, 50]
		]
	],
	'superleaderboard': [
		[
			[967, 0],
			[728, 90]
		],
		[
			[480, 0],
			[320, 50]
		],
		[
			[0, 0],
			[320, 50]
		]
	],
	'billboard': [
		[
			[1024, 0],
			[970, 250]
		],
		[
			[970, 0],
			[728, 90]
		],
		[
			[480, 0],
			[320, 50]
		],
		[
			[0, 0],
			[320, 50]
		]
	],
	'rectangle': [
		[
			[480, 0],
			[300, 250]
		],
		[
			[0, 0],
			[300, 250]
		]
	],
	'article_custom': [
		[
			[967, 0],
			[728, 400]
		],
		[
			[480, 0],
			[468, 60]
		],
		[
			[480, 0],
			[320, 50]
		],
		[
			[0, 0],
			[320, 50]
		]
	],
	'test_article_custom': [
		[
			[967, 0],
			[728, 400]
		],
		[
			[480, 0],
			[468, 60]
		],
		[
			[480, 0],
			[320, 50]
		],
		[
			[0, 0],
			[320, 50]
		]
	]
};

/**
 * Definition of all possible AdUnits according to:
 *   https://wiki.dfl-digital-sports.de/display/B2C/Ad+Units
 */
export const adUnits = {
	/*
    Table
     */
	'dfp_bl_web_de/table_superleaderboard_top_responsive': {
		size: adDimensions.superleaderboard,
		mapping: adSizeMapping.superleaderboard,
		tag: '1501164031143-0',
		adType: 'superleaderboard'
	},

	'test_dfp_bl_web_de/test_table_superleaderboard_top_responsive': {
		size: adDimensions.superleaderboard,
		mapping: adSizeMapping.superleaderboard,
		tag: '1499776042405-0',
		adType: 'superleaderboard'
	},

	/*
    Fixtures/Results
     */
	'dfp_bl_web_de/fixturesresults_superleaderboard_top_responsive': {
		size: adDimensions.superleaderboard,
		mapping: adSizeMapping.superleaderboard,
		tag: '1501160287123-0',
		adType: 'superleaderboard'
	},

	'test_dfp_bl_web_de/test_fixturesresults_superleaderboard_top_responsive': {
		size: adDimensions.superleaderboard,
		mapping: adSizeMapping.superleaderboard,
		tag: '1499777170517-0',
		adType: 'superleaderboard'
	},

	/*
    Homepage
     */
	'dfp_bl_web_de/home_billboard_top_responsive': {
		size: adDimensions.billboard,
		mapping: adSizeMapping.billboard,
		tag: '1501159697664-0',
		adType: 'billboard'
	},
	'dfp_bl_web_de/home_superleaderboard_middle_responsive': {
		size: adDimensions.superleaderboard,
		mapping: adSizeMapping.superleaderboard,
		tag: '1501159697664-1',
		adType: 'superleaderboard'
	},
	'dfp_bl_web_de/home_mediumrectangle_bottom_300x250': {
		size: adDimensions.rectangle,
		mapping: adSizeMapping.rectangle,
		tag: '1501159697664-2',
		adType: 'rectangle'
	},

	'test_dfp_bl_web_de/test_home_billboard_top_responsive': {
		size: adDimensions.billboard,
		mapping: adSizeMapping.billboard,
		tag: '1499784109420-0',
		adType: 'billboard'
	},
	'test_dfp_bl_web_de/test_home_superleaderboard_middle_responsive': {
		size: adDimensions.superleaderboard,
		mapping: adSizeMapping.superleaderboard,
		tag: '1499784190860-0',
		adType: 'superleaderboard'
	},
	'test_dfp_bl_web_de/test_home_mediumrectangle_bottom_300x250': {
		size: adDimensions.rectangle,
		mapping: adSizeMapping.rectangle,
		tag: '1499784295205-0',
		adType: 'rectangle'
	},

	/*
    Article
     */
	'dfp_bl_web_de/article_superleaderboard_top_responsive': {
		size: adDimensions.superleaderboard,
		mapping: adSizeMapping.superleaderboard,
		tag: '1501164446572-0',
		adType: 'superleaderboard'
	},
	'dfp_bl_web_de/article_custom-ad_middle_responsive': {
		size: adDimensions.article_custom,
		mapping: adSizeMapping.article_custom,
		tag: '1501164446572-1',
		adType: 'article_custom'
	},

	'test_dfp_bl_web_de/test_article_superleaderboard_top_responsive': {
		size: adDimensions.superleaderboard,
		mapping: adSizeMapping.superleaderboard,
		tag: '1500908892421-0',
		adType: 'superleaderboard'
	},
	'test_dfp_bl_web_de/test_article_custom-ad_middle_responsive': {
		size: adDimensions.test_article_custom,
		mapping: adSizeMapping.test_article_custom,
		tag: '1500908892421-1',
		adType: 'article_custom'
	},

	/*
    Club Page
     */
	'dfp_bl_web_de/club_superleaderboard_top_responsive': {
		size: adDimensions.superleaderboard,
		mapping: adSizeMapping.superleaderboard,
		tag: '1501165032010-0',
		adType: 'superleaderboard'
	},
	'dfp_bl_web_de/club_medium-rectangle_middle_300x250': {
		size: adDimensions.rectangle,
		mapping: adSizeMapping.rectangle,
		tag: '1501165032010-1',
		adType: 'rectangle'
	},

	'test_dfp_bl_web_de/test_club_superleaderboard_top_responsive': {
		size: adDimensions.superleaderboard,
		mapping: adSizeMapping.superleaderboard,
		tag: '1500980658574-0',
		adType: 'superleaderboard'
	},
	'test_dfp_bl_web_de/test_club_medium-rectangle_middle_300x250': {
		size: adDimensions.rectangle,
		mapping: adSizeMapping.rectangle,
		tag: '1500980658574-1',
		adType: 'rectangle'
	},

	/*
    Player Page
     */
	'dfp_bl_web_de/player_superleaderboard_top_responsive': {
		size: adDimensions.superleaderboard,
		mapping: adSizeMapping.superleaderboard,
		tag: '1501165260577-0',
		adType: 'superleaderboard'
	},
	'dfp_bl_web_de/player_medium-rectangle_middle_300x250': {
		size: adDimensions.rectangle,
		mapping: adSizeMapping.rectangle,
		tag: '1501165260577-1',
		adType: 'rectangle'
	},

	'test_dfp_bl_web_de/test_player_superleaderboard_top_responsive': {
		size: adDimensions.superleaderboard,
		mapping: adSizeMapping.superleaderboard,
		tag: '1500980934791-0',
		adType: 'superleaderboard'
	},
	'test_dfp_bl_web_de/test_player_medium-rectangle_middle_300x250': {
		size: adDimensions.rectangle,
		mapping: adSizeMapping.rectangle,
		tag: '1500980934791-1',
		adType: 'rectangle'
	},

	/*
    Matchcenter/Liveticker/LiveBlogging
     */
	// Matchcenter/Liveticker/LiveBlogging
	'dfp_bl_web_de/liveblogging-starting-whistle_leaderboard_responsive': {
		size: adDimensions.leaderboard,
		mapping: adSizeMapping.leaderboard,
		tag: '2122131784-0',
		adType: 'leaderboard'
	},
	'test_dfp_bl_web_de/test_liveblogging-starting-whistle_leaderboard_responsive': {
		size: adDimensions.leaderboard,
		mapping: adSizeMapping.leaderboard,
		tag: '1536741766425-0',
		adType: 'leaderboard'
	},
	'dfp_bl_web_de/liveblogging-extra-time_leaderboard_responsive': {
		size: adDimensions.leaderboard,
		mapping: adSizeMapping.leaderboard,
		tag: '2122131784-1',
		adType: 'leaderboard'
	},
	'test_dfp_bl_web_de/test_liveblogging-extra-time_leaderboard_responsive': {
		size: adDimensions.leaderboard,
		mapping: adSizeMapping.leaderboard,
		tag: '1536741845755-0',
		adType: 'leaderboard'
	},
	'dfp_bl_web_de/matchcenter_superleaderboard_top_responsive': {
		size: adDimensions.superleaderboard,
		mapping: adSizeMapping.superleaderboard,
		tag: '1501160008776-0',
		adType: 'superleaderboard'
	},
	'test_dfp_bl_web_de/test_matchcenter_superleaderboard_top_responsive': {
		size: adDimensions.superleaderboard,
		mapping: adSizeMapping.superleaderboard,
		tag: '1501067482243-0',
		adType: 'superleaderboard'
	},
	'test_dfp_bl_app/test_liveticker-new': {
		size: adDimensions.appboard,
		mapping: adSizeMapping.appboard,
		tag: '1536828154477-0',
		adType: 'appboard'
	},
	'dfp_bl_app/liveticker-new': {
		size: adDimensions.appboard,
		mapping: adSizeMapping.appboard,
		tag: '1536828100737-0',
		adType: 'appboard'
	},

	/*
    Supercup Promo Page
     */
	'dfp_bl_web_de/brandspace-supercup_billboard_responsive': {
		size: adDimensions.billboard,
		mapping: adSizeMapping.billboard,
		tag: '1627642016670-0',
		adType: 'billboard'
	},
	'test_dfp_bl_web_de/test_brandspace-supercup_billboard_responsive': {
		size: adDimensions.billboard,
		mapping: adSizeMapping.billboard,
		tag: '1627641911343-0',
		adType: 'billboard'
	},

	/*
    Brand Space
     */
	'dfp_bl_web_de/brandspace_billboard_responsive': {
		size: adDimensions.billboard,
		mapping: adSizeMapping.billboard,
		tag: '1506761477483-0',
		adType: 'billboard'
	},
	'test_dfp_bl_web_de/test_brandspace_billboard_responsive': {
		size: adDimensions.billboard,
		mapping: adSizeMapping.billboard,
		tag: '1506761352159-0',
		adType: 'billboard'
	},

	/*
    Brand Space Tipico
     */
	'dfp_bl_web_de/brandspace-tipico_billboard_responsive': {
		size: adDimensions.billboard,
		mapping: adSizeMapping.billboard,
		tag: '1510689221676-0',
		adType: 'billboard'
	},

	'test_dfp_bl_web_de/test_brandspace-tipico_billboard_responsive': {
		size: adDimensions.billboard,
		mapping: adSizeMapping.billboard,
		tag: '1510172294973-0',
		adType: 'billboard'
	},

	/*
     Brand Space Topps
      */
	'dfp_bl_web_de/brandspace-topps_billboard_responsive': {
		size: adDimensions.billboard,
		mapping: adSizeMapping.billboard,
		tag: '1510689869509-0',
		adType: 'billboard'
	},

	'test_dfp_bl_web_de/test_brandspace-topps_billboard_responsive': {
		size: adDimensions.billboard,
		mapping: adSizeMapping.billboard,
		tag: '151068999205-0',
		adType: 'billboard'
	},

	/*
    Brand Space Derbystar
     */
	'dfp_bl_web_de/brandspace-derbystar_billboard_responsive': {
		size: adDimensions.billboard,
		mapping: adSizeMapping.billboard,
		tag: '1532588705492-0',
		adType: 'billboard'
	},

	'test_dfp_bl_web_de/test_brandspace-derbystar_billboard_responsive': {
		size: adDimensions.billboard,
		mapping: adSizeMapping.billboard,
		tag: '1532588526485-0',
		adType: 'billboard'
	},

	/*
  Brand Space Milka
   */
	'dfp_bl_web_de/brandspace-milka_billboard_responsive': {
		size: adDimensions.billboard,
		mapping: adSizeMapping.billboard,
		tag: '1607964961446-0',
		adType: 'billboard'
	},

	'test_dfp_bl_web_de/test_brandspace-milka_billboard_responsive': {
		size: adDimensions.billboard,
		mapping: adSizeMapping.billboard,
		tag: '1607964961446-0',
		adType: 'billboard'
	},

	/*
      Brand Space goalplay
    */
	'dfp_bl_web_de/brandspace-goalplay_billboard_responsive': {
		size: adDimensions.billboard,
		mapping: adSizeMapping.billboard,
		tag: '1532588705492-0',
		adType: 'billboard'
	},

	'test_dfp_bl_web_de/test_brandspace-goalplay_billboard_responsive': {
		size: adDimensions.billboard,
		mapping: adSizeMapping.billboard,
		tag: '1532588526485-0',
		adType: 'billboard'
	},

	/*
    Brand Space AWS
     */
	'dfp_bl_web_de/brandspace-aws_billboard_responsive': {
		size: adDimensions.billboard,
		mapping: adSizeMapping.billboard,
		// tag: '1532588705492-0',
		tag: '1578428977881-0',
		adType: 'billboard'
	},

	'test_dfp_bl_web_de/test_brandspace-aws_billboard_responsive': {
		size: adDimensions.billboard,
		mapping: adSizeMapping.billboard,
		// tag: '1532588526485-0',
		tag: '1578429247095-0',
		adType: 'billboard'
	},

	/*
    News Hub
     */
	'dfp_bl_web_de/news-hub_medium-rectangle_300x250': {
		size: adDimensions.rectangle,
		mapping: adSizeMapping.rectangle,
		tag: '1510600502981-0',
		adType: 'rectangle'
	},

	'test_dfp_bl_web_de/test_news-hub_medium-rectangle_300x250': {
		size: adDimensions.rectangle,
		mapping: adSizeMapping.rectangle,
		tag: '1510600728526-0',
		adType: 'rectangle'
	},

	/*
    Video Hub
     */
	'dfp_bl_web_de/video-hub_medium-rectangle_300x250': {
		size: adDimensions.rectangle,
		mapping: adSizeMapping.rectangle,
		tag: '1510601004979-0',
		adType: 'rectangle'
	},
	'test_dfp_bl_web_de/test_video-hub_medium-rectangle_300x250': {
		size: adDimensions.rectangle,
		mapping: adSizeMapping.rectangle,
		tag: '1510600873709-0',
		adType: 'rectangle'
	}
};
