import { Component, Inject, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getWorkingLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';
import { ConsentCategories, CookieConsentService } from '@nx-bundesliga/bundesliga-com/services/cookie-consent';
import { Themes, ThemingService } from '@nx-bundesliga/bundesliga-com/services/theming';
import { BundesligaEmbed, Language } from '@nx-bundesliga/models';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
	selector: 'embed-wrapper',
	templateUrl: './embed-wrapper.component.html',
	styleUrls: ['./embed-wrapper.component.scss']
})
export class EmbedWrapperComponent implements OnInit {
	@Input() embed: BundesligaEmbed;
	@Input() consented = false;
	@Input() showRevokeConsentToggle = true;
	public language$: Observable<string>;
	public shouldBeDisplayed = false;
	public isWebview = false;
	public src: string;
	public theme$: Observable<Themes>;
	public consents$: Observable<ConsentCategories>;
	public embedPrivacyLink = '';
	private privacyLinks = {
		'facebook': 'https://www.facebook.com/policy.php',
		'instagram': 'https://help.instagram.com/155833707900388',
		'twitter': 'https://twitter.com/privacy',
		'x': 'https://twitter.com/privacy',
		'apester': 'https://apester.com/privacy-policy',
		'monterosa': 'https://monterosa.co/privacy-policy'
	};

	constructor(private cookieConsent: CookieConsentService, private themingService: ThemingService, private readonly lstore: Store<Language>, private config: ConfigService, @Inject(WINDOW) private window: Window) {
		this.theme$ = this.themingService.theme$;
		this.consents$ = this.cookieConsent.consents$;
		this.language$ = this.lstore.pipe(
			select(getWorkingLanguage),
			filter((lang: Language) => lang.code !== ''),
			map((lang: Language) => lang.code)
		);
	}

	ngOnInit() {
		this.isWebview = this.config.getSettings('webview', false);
		if (this.embed.embedPlatform === 'youtube') {
			if (!this.embed.snipped && this.embed.embedId) {
				this.src = `https://www.youtube-nocookie.com/embed/${this.embed.embedId}${this.embed.autoplay ? '?autoplay=1&mute=1' : ''}`;
			} else if (this.embed.url && this.embed.url !== '') {
				this.embed.embedId = this.getYoutubeIdFromUrl(this.embed.url);
				this.src = `https://www.youtube-nocookie.com/embed/${this.embed.embedId}${this.embed.autoplay ? '?autoplay=1&mute=1' : ''}`;
			}
		}
		if (this.embed && this.embed.embedPlatform && this.privacyLinks.hasOwnProperty(this.embed.embedPlatform)) {
			this.embedPrivacyLink = this.privacyLinks[this.embed.embedPlatform];
		}
	}

	public toggleEmbedConsent() {
		this.consented = !this.consented;
	}

	/**
	 * Should be called when - or short before - the component enters the viewport.
	 * Once this is set to true the child embed components will be loaded and displayed.
	 */
	public isInViewNow() {
		this.shouldBeDisplayed = true;
	}

	public getYoutubeIdFromUrl(url) {
		const match = url.match(/v=([0-9a-z_-]{1,20})/i);
		return match ? match['1'] : false;
	}

	public openOnetrustSettings() {
		if (this.window && this.window.hasOwnProperty('OneTrust')) {
			this.window['OneTrust'].ToggleInfoDisplay();
		}
	}
}
