<mat-card>
	<mat-card-header>
		<mat-icon color="warn" *ngIf="!slide?.valid" matTooltip="This slide has an Error! Please fill out all fields correctly!">error</mat-icon>
		<div class="infos" title="(ID: {{slide.value?.slideId}})"> #{{ index + 1 }} {{ slide.value?.slideType | titlecase }}</div>
		<div class="actions">
			<ng-content select="[headerActions]"></ng-content>
		</div>
	</mat-card-header>

	<div class="match_story_header">
		<div class="playtime" *ngIf="storyType === 'match_story' && (slide.value?.playtime?.minute >= 0 || slide.value?.playtime?.injuryTime >= 0)">
			<span class="minute">{{slide.value?.playtime?.minute ?? 0}}'</span>
			<span class="injuryTime" *ngIf="slide.value?.playtime?.injuryTime > 0">+{{slide.value?.playtime?.injuryTime}}</span>
		</div>
		<div class="score" *ngIf="storyType === 'match_story' && slide.value?.score && (slide.value?.score?.home >= 0 || slide.value?.score?.away >= 0)">
			<span class="home">{{slide.value?.score?.home}}</span>
			<span class="away">{{slide.value?.score?.away}}</span>
		</div>
	</div>

	<div class="slide" [style.background-image]="slide.value?.image?.url ? 'url(' + (slide.value?.image?.url | imageService:304:540) + ')' : ''">
		<button mat-flat-button color="primary" class="cta" *ngIf="slide.value?.cta?.text && slide.value?.cta?.target" [matTooltip]="'Linking to: ' + slide.value?.cta?.target">
			<mat-icon>star_rating</mat-icon>
			{{slide.value?.cta?.text}}
		</button>

		<!-- VIDEO -->
		<jwplayer-video *ngIf="slide.value?.slideType === slideTypes.VIDEO && slide.value?.video?.vid" class="w-100" controls [src]="'https://cdn.jwplayer.com/players/' + slide.value?.video?.vid + '-' + jwPlayerId"></jwplayer-video>

		<!-- MONTEROSA -->
		<!--<embed-wrapper *ngIf="slide.value?.slideType === slideTypes.MONTEROSA && slide.value?.monterosa?.eventId && slide.value?.monterosa?.projectId" class="w-100" [embed]="{embedPlatform: 'monterosa', eventId: slide.value?.monterosa?.eventId, projectId: slide.value?.monterosa?.projectId }"></embed-wrapper>-->
		<div class="slide-overlay slide--text" *ngIf="slide.value?.slideType === slideTypes.MONTEROSA && slide.value?.monterosa?.eventId && slide.value?.monterosa?.projectId">
			<p class="slide--text-headline">EventId: {{ slide.value?.monterosa?.eventId }}</p>
			<p class="slide--text-headline">ProjectId: {{ slide.value?.monterosa?.projectId }}</p>
		</div>

		<!-- TEXT or VIDEO -->
		<div class="slide-overlay slide--text" *ngIf="slide.value?.slideType === slideTypes.TEXT || slide.value?.slideType === slideTypes.VIDEO">
			<p class="slide--text-headline">{{ slide.value?.headline }}</p>
			<p>{{ slide.value?.text }}</p>
		</div>

		<!-- QUOTE -->
		<div class="slide-overlay slide--quote" *ngIf="slide.value?.slideType === slideTypes.QUOTE">
			<p>{{ slide.value?.quote }}</p>
			<p class="slide--quote-author">{{ slide.value?.author }}</p>
		</div>

		<!-- GOAL -->
		<div class="slide-overlay slide--content" *ngIf="slide.value?.slideType === slideTypes.GOAL">
			<h2 class="text-uppercase">{{slide?.value?.goalType}}</h2>
			<p>
				<img class="player-image" [dflLazyLoad]="slide?.value?.scorer?.imageUrl| playerImage: 'FACE_CIRCLE' | imageService : 96" defaultImage="/assets/placeholder/player-circle-default.png" errorImage="/assets/placeholder/player-circle-default.png" />
				<span class="player-name">{{slide?.value?.scorer?.name}}</span>
			</p>
			<p>Penalty: {{slide?.value?.penalty}}</p>
			<p>xG: {{slide?.value?.xG}}</p>
			<p>distanceToGoal: {{slide?.value?.distanceToGoal}}</p>
			<p>shotSpeed: {{slide?.value?.shotSpeed}}</p>
			<!--<ng-container [ngTemplateOutlet]="slideDataDetails"></ng-container>-->
		</div>

		<!-- PROGRESS -->
		<div class="slide-overlay slide--content" *ngIf="slide.value?.slideType === slideTypes.PROGRESS">
			<h3>Match progression:</h3>
			<h2 class="text-uppercase">{{slide.value?.progressType}}</h2>
			<!--<ng-container [ngTemplateOutlet]="slideDataDetails"></ng-container>-->
		</div>

		<!-- LINEUP -->
		<div class="slide-overlay slide--content" *ngIf="slide.value?.slideType === slideTypes.LINEUP">
			<h2>Lineup for {{slide.value?.dflDatalibraryClubId}}</h2>
			<p *ngFor="let person of slide.value?.persons ?? []">
				<img class="lineup-player-image" [dflLazyLoad]="person?.imageUrl| playerImage: 'FACE_CIRCLE' | imageService : 96" defaultImage="/assets/placeholder/player-circle-default.png" errorImage="/assets/placeholder/player-circle-default.png" />
				<span class="player-shirtNumber">({{person?.shirtNumber}})</span>
				<span class="player-name">{{person?.name}}</span>
			</p>
			<!--<ng-container [ngTemplateOutlet]="slideDataDetails"></ng-container>-->
		</div>

		<!-- CARD -->
		<div class="slide-overlay slide--content" *ngIf="slide.value?.slideType === slideTypes.CARD">
			<h2 class="text-uppercase">{{slide?.value?.cardType}}</h2>
			<p>
				<img class="player-image" [dflLazyLoad]="slide?.value?.person?.imageUrl| playerImage: 'FACE_CIRCLE' | imageService : 96" defaultImage="/assets/placeholder/player-circle-default.png" errorImage="/assets/placeholder/player-circle-default.png" />
				<span class="player-name">{{slide?.value?.person?.name}}</span>
			</p>
			<!--<ng-container [ngTemplateOutlet]="slideDataDetails"></ng-container>-->
		</div>

		<!-- SUB -->
		<div class="slide-overlay slide--content" *ngIf="slide.value?.slideType === slideTypes.SUB">
			<h2>IN</h2>
			<p>
				<img class="player-image" [dflLazyLoad]="slide?.value?.in?.imageUrl| playerImage: 'FACE_CIRCLE' | imageService : 96" defaultImage="/assets/placeholder/player-circle-default.png" errorImage="/assets/placeholder/player-circle-default.png" />
				<span class="player-name">{{slide?.value?.in?.name}}</span>
			</p>
			<hr />
			<h2>OUT</h2>
			<p>
				<img class="player-image" [dflLazyLoad]="slide?.value?.out?.imageUrl| playerImage: 'FACE_CIRCLE' | imageService : 96" defaultImage="/assets/placeholder/player-circle-default.png" errorImage="/assets/placeholder/player-circle-default.png" />
				<span class="player-name">{{slide?.value?.out?.name}}</span>
			</p>
			<!--<ng-container [ngTemplateOutlet]="slideDataDetails"></ng-container>-->
		</div>

		<div class="slide-overlay slide--details">
			<mat-expansion-panel class="w-100">
				<mat-expansion-panel-header>
					<mat-panel-title>Show {{slide.value?.slideType}} data</mat-panel-title>
				</mat-expansion-panel-header>
				<pre>{{slide.value | json}}</pre>
			</mat-expansion-panel>
		</div>

		<ng-template #slideDataDetails>
			<ng-container *ngIf="[slideTypes.GOAL, slideTypes.SUB, slideTypes.CARD, slideTypes.LINEUP, slideTypes.PROGRESS].includes(slide.value?.slideType)">
				<mat-expansion-panel class="w-100">
					<mat-expansion-panel-header>
						<mat-panel-title>Show {{slide.value?.slideType}} data</mat-panel-title>
					</mat-expansion-panel-header>
					<pre>{{slide.value | json}}</pre>
				</mat-expansion-panel>
			</ng-container>
		</ng-template>
	</div>

	<mat-card-actions>
		<ng-content select="[actions]"></ng-content>
	</mat-card-actions>
</mat-card>
