import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'dfl-simple-icon',
	templateUrl: './dfl-simple-icon.component.html',
	styleUrls: ['./dfl-simple-icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DflSimpleIconComponent {
	@Input() icon: Icon;
	@Input() force: IconMode = 'auto';
	@Input() height = 25;
	@Input() width = 25;

	constructor() {}
}

export type IconMode = 'light' | 'dark' | 'invert' | 'none' | 'auto';
export type Icon =
	| 'link-out'
	| 'rank-down'
	| 'rank-neutral'
	| 'table'
	| 'app'
	| 'arrow-complex-down'
	| 'arrow-complex-download'
	| 'arrow-complex-left'
	| 'arrow-complex-right'
	| 'arrow-complex-up'
	| 'arrow-complex-upload'
	| 'arrow-simple-down'
	| 'arrow-simple-left'
	| 'arrow-simple-right'
	| 'arrow-simple-up'
	| 'ball-goal-number'
	| 'ball-goal'
	| 'ball-highlights'
	| 'ball-opinion'
	| 'ball-owngoal-number'
	| 'ball-owngoal'
	| 'ball-speed'
	| 'bookmark'
	| 'broadcaster'
	| 'camera-meeting'
	| 'camera-photo'
	| 'camera-professional-play-symbol'
	| 'camera-professional'
	| 'camera-studio'
	| 'card-given'
	| 'card-red'
	| 'card-yellow-red'
	| 'card-yellow'
	| 'cards'
	| 'check'
	| 'close'
	| 'club'
	| 'copy-link'
	| 'design-and-production'
	| 'diagram-1'
	| 'diagram-2'
	| 'disability'
	| 'edit'
	| 'explanation'
	| 'eye-off'
	| 'eye-on'
	| 'face-match-previous'
	| 'facebook'
	| 'fantasy-manager'
	| 'field-horizontal'
	| 'field-vertical'
	| 'football'
	| 'goal'
	| 'handle-horizontal-2x'
	| 'handle-side'
	| 'handshake'
	| 'heart-1'
	| 'heart-beat'
	| 'heart'
	| 'home feed'
	| 'home house'
	| 'honor'
	| 'info-small-1'
	| 'info-small'
	| 'information'
	| 'information-ar'
	| 'instagram'
	| 'international'
	| 'jersey'
	| 'legal notice'
	| 'linkedin'
	| 'list-1'
	| 'list-2'
	| 'lock'
	| 'logo-apple-negative'
	| 'logo-apple'
	| 'logo-facebook-1'
	| 'logo-facebook'
	| 'logo-google'
	| 'mail'
	| 'map-with-pin'
	| 'map'
	| 'media-composition'
	| 'menu'
	| 'more'
	| 'multimedia'
	| 'notification-off'
	| 'notification-ringing'
	| 'notification'
	| 'notifications-eye-off'
	| 'notifications-eye-on'
	| 'os-android'
	| 'os-ios'
	| 'phone-hand-hold'
	| 'phone-mobile-device'
	| 'phone'
	| 'pin'
	| 'play-highlight'
	| 'play'
	| 'play-filled'
	| 'predictor'
	| 'privacy check'
	| 'privacy settings'
	| 'question'
	| 'rank-up'
	| 'rankings statistics'
	| 'ruler'
	| 'save'
	| 'search'
	| 'settings'
	| 'shot'
	| 'skype'
	| 'soccer-player-dribble'
	| 'soccer-player-runner'
	| 'soccer-player-shot-front'
	| 'soccer-player-shot'
	| 'soccer-player-tactic'
	| 'soccer-player-warm-up'
	| 'social-media-bubble'
	| 'social-media'
	| 'sounds-off'
	| 'sounds-on'
	| 'statistic'
	| 'submenu'
	| 'substitution-in-horizontal'
	| 'substitution-in-out-vertical'
	| 'substitution-in-vertical'
	| 'substitution-out-horizontal'
	| 'substitution-out-vertical'
	| 'substitution'
	| 'table standings'
	| 'talk'
	| 'target'
	| 'thumb-up'
	| 'tickets'
	| 'twitter'
	| 'x'
	| 'ui_small-arrow-down-dark'
	| 'ui_small-arrow-up-dark'
	| 'ui_small-arrow-up-white'
	| 'ui_small-check-dark'
	| 'ui_small-check-light'
	| 'ui_small-check-white'
	| 'ui_small-close-dark'
	| 'ui_small-close-light'
	| 'ui_small-minus'
	| 'ui_small-plus'
	| 'ui_smallarrow-down-white'
	| 'user-loggedout'
	| 'user-loggedin'
	| 'user-logout'
	| 'var-icon'
	| 'var-logo'
	| 'video-cloud'
	| 'websites-desktop'
	| 'websites-responsive'
	| 'whatsapp'
	| 'whistle-no'
	| 'whistle'
	| 'xing'
	| 'youtube';
