/*
 * Original script: https://github.com/alaborderie/ng2-ad-dfp/blob/master/src/ad-dfp.ts
 */
import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { ConsentCategories, CookieConsentService } from '@nx-bundesliga/bundesliga-com/services/cookie-consent';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import { DfpAdsService } from '../service/dfp-ads.service';
import { adUnits } from './dfp-ads.sizes';

declare let googletag: any;
declare let window: any;

@Component({
	selector: 'dfp-ads',
	templateUrl: './dfp-ads.component.html',
	styleUrls: ['./dfp-ads.component.scss']
})
export class DfpAdsComponent implements OnInit, OnChanges, OnDestroy {
	@Input() adName: string;
	@Output() adDisplayed: EventEmitter<boolean> = new EventEmitter<boolean>(true);

	public network = '';
	public dfp: any;
	public dfpAdName: string;
	public dfpAdLoaded = true; /* default setting if ad container is displayed */
	public dfpAdConsented = false;
	public adSlotRenderedSuccess = false;

	private definedSlot: any;
	private loaderSubscription: Subscription;
	private slotRenderEndedSubscription: Subscription;
	private isBrowser: boolean;

	constructor(private dfpAdsService: DfpAdsService, public configService: ConfigService, private cookieConsent: CookieConsentService, private ngZone: NgZone, @Inject(PLATFORM_ID) platformId: Object) {
		this.isBrowser = isPlatformBrowser(platformId);
		if (this.isBrowser) {
			window.googletag = window.googletag || {};
			googletag.cmd = googletag.cmd || [];
		}
	}

	/**
	 * Called when the component is loading
	 */
	ngOnInit() {
		if (this.isBrowser) {
			this.network = this.configService.getSettings('dfp.network');
			this._emitDfpAdLoadingStatus(true);
			this.ngZone.runOutsideAngular(() => {
				this.loaderSubscription = this.dfpAdsService.load().subscribe(
					(loaded: boolean) => {
						// set Ad-Sizes after first successful load
						if (loaded === true) {
							// display ad afterwards
							if (typeof googletag !== typeof undefined) {
								this.displayAd();
							} else {
								this._emitDfpAdLoadingStatus(false);
							}
						} else {
							this._emitDfpAdLoadingStatus(false);
						}
					},
					(error) => {
						// do nothing if rejected. Meaning script loading was blocked
						this._emitDfpAdLoadingStatus(false);
					}
				);
			});
		}
	}

	ngOnChanges() {
		if (this.isBrowser) {
			this.dfpAdName = this.adName;
			if (this.configService.getSettings('production') === false) {
				this.dfpAdName = this.adName
					.split('/')
					.map((value) => 'test_' + value)
					.join('/');
			}
			if (adUnits.hasOwnProperty(this.dfpAdName)) {
				this.dfp = adUnits[this.dfpAdName];
			}
		}
	}

	ngOnDestroy() {
		if (this.loaderSubscription) {
			this.loaderSubscription.unsubscribe();
		}
		if (this.slotRenderEndedSubscription) {
			this.slotRenderEndedSubscription.unsubscribe();
		}
		// needs to be run when component is destroyed to avoid errors when returning to a page where ads have been displayed previously
		this.ngZone.runOutsideAngular(() => {
			if (typeof googletag !== typeof undefined && googletag.apiReady && this.definedSlot) {
				googletag.cmd.push(() => {
					// destroy slot
					googletag.destroySlots([this.definedSlot]);
					// googletag.pubads().clear([this.definedSlot]);
				});
			}
		});
	}

	/**
	 * Displays an ad
	 */
	displayAd(): void {
		this.ngZone.runOutsideAngular(() => {
			this.adSlotRenderedSuccess = false;
			if (this.dfp && typeof googletag !== typeof undefined && googletag.apiReady) {
				googletag.cmd.push(() => {
					this.definedSlot = googletag
						.defineSlot('/' + this.network + '/' + this.dfpAdName, this.dfp['size'], 'div-gpt-ad-' + this.dfp['tag'])
						.defineSizeMapping(this.dfp['mapping'])
						.addService(googletag.pubads());

					this.slotRenderEndedSubscription = this.dfpAdsService
						.slotRenderEnded(this.definedSlot)
						.pipe(
							mergeMap((slot: { rendered: boolean; consented: boolean }) => {
								this.adSlotRenderedSuccess = true;
								this.dfpAdConsented = slot.consented;
								this._emitDfpAdLoadingStatus(slot.rendered);
								return this.cookieConsent.consents$;
							}),
							map((consents: ConsentCategories) => (consents.hasOwnProperty('C0004') ? consents['C0004'] : false)),
							filter((consent) => consent !== this.dfpAdConsented), // avoid refreshing ads even though they have not changed from initial call
							distinctUntilChanged()
						)
						.subscribe(
							(consented: boolean) => {
								this.dfpAdConsented = consented;
								googletag.cmd.push(() => {
									googletag.pubads().setCookieOptions(consented === true ? 0 : 1);
									googletag.pubads().refresh([this.definedSlot]);
								});
							},
							(err: string) => {}
						);
					googletag.display('div-gpt-ad-' + this.dfp['tag']);
					googletag.pubads().refresh([this.definedSlot]);

					if (this.adSlotRenderedSuccess === false) {
						this._emitDfpAdLoadingStatus(false);
					}
				});
			} else {
				this._emitDfpAdLoadingStatus(false);
			}
		});
	}

	private _emitDfpAdLoadingStatus(loaded: boolean): void {
		this.dfpAdLoaded = loaded;
		this.adDisplayed.emit(loaded);
	}
}
