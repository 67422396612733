<ng-container *ngIf="isWebview === false && (awsPartnerPageUrl$ | async) as awsPartnerPageUrl; else fallback">
	<!-- Render linked image if awsPartnerPageUrl is resolved and not a webview -->
	<a class="matchfacts-sponsor" [href]="awsPartnerPageUrl | translateRoute" target="_blank">
		<dfl-image height="40" dark="/assets/liveticker/powered-by-aws-white.svg" light="/assets/liveticker/powered-by-aws.svg"></dfl-image>
	</a>
</ng-container>

<ng-template #fallback>
	<!-- Render fallback image -->
	<dfl-image class="matchfacts-sponsor" height="40" dark="/assets/liveticker/powered-by-aws-white.svg" light="/assets/liveticker/powered-by-aws.svg"></dfl-image>
</ng-template>
