<div class="container" *ngIf="showMonterosa" [id]="instanceId"></div>
<div class="wrapper" *ngIf="!showMonterosa">
	<div class="app-download-card">
		<div class="logo">
			<img [ngSrc]="(monterosa?.placeholder?.image?.mobile?.url || monterosa?.placeholder?.image?.liveticker?.url) ?? ('MONTEROSA.PLACEHOLDER.IMAGE' | translate)" [alt]="monterosa?.placeholder?.title ?? ('MONTEROSA.PLACEHOLDER.TITLE' | translate)" fill style="position: relative" priority />
		</div>
		<div class="app-banner-body">
			<!--<h2 class="title">{{monterosa?.placeholder?.title ?? ('MONTEROSA.PLACEHOLDER.TITLE' | translate)}}</h2>
			<p class="description">{{monterosa?.placeholder?.description ?? ('MONTEROSA.PLACEHOLDER.DESCRIPTION' | translate)}}</p>-->
			<a [href]="monterosa?.placeholder?.target ?? ('MONTEROSA.PLACEHOLDER.TARGET' | translate)">
				<button class="download-button">{{'MONTEROSA.PLACEHOLDER.TITLE' | translate}}</button>
			</a>
		</div>
	</div>
</div>
