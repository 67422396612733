<ng-container *ngIf="video">
	<h2 *ngIf="showHeadline && video.headline">{{video.headline}}</h2>
	<!--<div class="playerContainer" (mouseover)="thumbailHovered=true" (mouseout)="thumbailHovered=false">-->
	<div class="playerContainer">
		<div class="player" #player *ngIf="jwState !== 0" [ngStyle]="styles" (click)="initalizeJWPlayer(true)">
			<ng-container *ngIf="autoplay === false && lazyLoad === true && thumbailHovered === false" [ngTemplateOutlet]="poster"></ng-container>
			<ng-container *ngIf="enableAnimatedThumbail && thumbailHovered === true" [ngTemplateOutlet]="thumbnailAnimated"></ng-container>
		</div>
		<div class="player errorContainer" *ngIf="jwState === 0" [ngStyle]="styles" (click)="initalizeJWPlayer(true)">
			<ng-container *ngIf="thumbailHovered === false" [ngTemplateOutlet]="poster"></ng-container>
			<ng-container *ngIf="enableAnimatedThumbail && thumbailHovered === true" [ngTemplateOutlet]="thumbnailAnimated"></ng-container>
		</div>
	</div>
	<div *ngIf="showDescription && video.text" class="caption">{{ video.text }}</div>
	<dfl-json-ld *ngIf="showJsonLd && jsonLd && jsonLd !== {}" [json]="jsonLd"></dfl-json-ld>
</ng-container>

<ng-template #poster>
	<picture>
		<source media="(min-width: 768px)" [srcset]="videoPosterStill | imageService:750" />
		<source media="(max-width: 767px)" [srcset]="videoPosterStill | imageService:540" />
		<img *ngIf="videoPosterStill" [dflLazyLoad]="videoPosterStill | imageService:540" [errorImage]="errorImage" [useSrcset]="true" [ngSrc]="defaultImage" alt="" fill priority />
	</picture>
	<button class="button-play"></button>
	<div class="videoOverlayAnimation"></div>
</ng-template>

<ng-template #thumbnailAnimated>
	<video *ngIf="videoPosterAnimated && videoPosterAnimated !== ''" class="videoThumbAnimated" loop autoplay muted preload="metadata">
		<source src="{{ videoPosterAnimated }}" type="video/mp4" />
	</video>
</ng-template>
