// angular
import { Inject, Injectable } from '@angular/core';
// libs
import { Router } from '@angular/router';
import { AmplitudeABTestVersion, AmplitudeCompetition, AmplitudeEventClick, AmplitudeEventInteraction, AmplitudePageViewEvent, AnalyticsTrack, ProfileAccount, ProfileSocialProvider } from '@nx-bundesliga/models';
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';
import { Title } from '@angular/platform-browser';

@Injectable()
export class AnalyticsAmplitudeService {
	public dataLayer: any;

	constructor(@Inject(WINDOW) private window: Window, private router: Router, private title: Title) {
		this.dataLayer = (<any>this.window).dataLayer;
		// The dataLayer needs to be initialized, use windowservice to avoid errors on server
		if (typeof (<any>this.window).dataLayer !== 'undefined' && (<any>this.window).dataLayer) {
			this.dataLayer = (<any>this.window).dataLayer || [];
		}
	}

	mapCompetitionName(competition: string): string {
		if (competition === 'bundesliga') {
			return 'Bundesliga';
		} else if (competition === '2bundesliga') {
			return 'Bundesliga 2';
		} else if (competition === 'euro') {
			return 'Euro Championship';
		}
		return 'Other';
	}

	mapUserLoginProvider(socialProvider: ProfileSocialProvider): string {
		if (socialProvider.hasOwnProperty('socialLogin') && socialProvider.socialLogin === true) {
			if (socialProvider.hasOwnProperty('apple') && socialProvider.apple === true) {
				return 'Apple SSO';
			} else if (socialProvider.hasOwnProperty('facebook') && socialProvider.facebook === true) {
				return 'Facebook SSO';
			} else if (socialProvider.hasOwnProperty('google') && socialProvider.google === true) {
				return 'Google SSO';
			} else {
				return 'SSO not set';
			}
		} else {
			return 'EMail';
		}
	}

	public sendPageviewToGTMAmplitude(path: string, custom: { [key: string]: any }, profile: ProfileAccount) {
		if (typeof this.dataLayer !== 'undefined' && this.dataLayer && document) {
			const url = this.router.url;
			const segments = url.split('/').filter((segment) => segment); // Split and filter empty segments
			const language = segments[0]; // Assuming the language is always the first segment
			const competition = segments[1] || ''; // Assuming the product is always the second segment
			const amplitudeCompetition = this.mapCompetitionName(competition) as AmplitudeCompetition;
			const pageType = segments[2] || ''; // Combine remaining segments for the category
			const amplitudePageType = custom.hasOwnProperty('amplitudePageType') && custom.amplitudePageType !== '' ? custom.amplitudePageType : '';

			const pageViewEvent: AmplitudePageViewEvent = {
				provider: 'amplitude',
				event: 'page_view',
				eventData: {
					competition: amplitudeCompetition,
					event_category: 'General',
					page_url: path,
					page_type: amplitudePageType,
					page_name: this.title.getTitle()
				},
				userData: {
					user_login_status: false,
					user_web_language: language
				}
			};

			if (profile && profile.user && profile.authorized === true && profile.isVerified === true && profile.registrationComplete === true) {
				pageViewEvent.userData = {
					user_cdp_opt_out: profile.user.crossAccountProfileOptout || false,
					user_ciam_id: profile.user.uid,
					user_login_status: true,
					user_web_language: language,
					user_country: profile.user.country,
					user_login_provider: this.mapUserLoginProvider(profile.socialProvider)
				};

				if (profile.user?.birthDate !== '') {
					pageViewEvent.userData.user_year_of_birth = Number(profile.user.birthDate.substring(0, 4));
				}
				if (profile.user?.favorite.teams[0] !== '') {
					pageViewEvent.userData.user_favorite_club = profile.user?.favorite.teams[0];
				}
			}
			if (custom.hasOwnProperty('virtPageTitle') && custom.virtPageTitle !== '') {
				pageViewEvent.eventData.page_name = custom.virtPageTitle;
			}

			if (custom.hasOwnProperty('DocumentID') && custom.DocumentID !== '') {
				pageViewEvent.eventData.media_id = custom.DocumentID;
			}

			if (custom.hasOwnProperty('newsletter')) {
				if (custom.newsletter.hasOwnProperty('bundesligaNewsletter')) {
					pageViewEvent.userData.user_newsletter_bundesliga_opt_in = custom.newsletter.bundesligaNewsletter;
				}
				if (custom.newsletter.hasOwnProperty('partnerNewsletter')) {
					pageViewEvent.userData.user_newsletter_partner_opt_in = custom.newsletter.partnerNewsletter;
				}
				if (custom.newsletter.hasOwnProperty('fantasyNewsletter')) {
					pageViewEvent.userData.user_newsletter_fantasy_opt_in = custom.newsletter.fantasyNewsletter;
				}
			}
			this.dataLayer.push(pageViewEvent);
		}
	}

	public sendEventToGTMAmplitude(properties: AnalyticsTrack): void {
		if (typeof this.dataLayer !== 'undefined' && this.dataLayer) {
			const eventType = this.buildAmplitudeEventType(properties);

			let dataLayerEvent: AmplitudeEventInteraction | AmplitudeEventClick = null;

			if (eventType === 'click') {
				dataLayerEvent = this.buildAmplitudeClickEvent(properties);
			} else if (eventType === 'interaction') {
				dataLayerEvent = this.buildAmplitudeInteractionEvent(properties);
			}

			if (dataLayerEvent !== null) {
				this.dataLayer.push(dataLayerEvent);
			}
		}
	}

	buildAmplitudeEventType(properties: AnalyticsTrack): string {
		// concats the eventName from legacy attributes
		const eventType = properties.event ? properties.event : properties.eventType ? properties.eventType : properties.eventName ? properties.eventName : 'type-not-set';
		return eventType;
	}

	buildAmplitudeFallbackEventName(eventType: string, properties: AnalyticsTrack): string {
		// concats the eventName from legacy attributes
		const fallBackEventName = `${properties.category.toLowerCase().replace(/ /g, '-')}_${eventType}`;
		return fallBackEventName;
	}

	buildAmplitudeClickEvent(properties: AnalyticsTrack): AmplitudeEventClick {
		const eventType = this.buildAmplitudeEventType(properties);
		const dataLayerEvent = {
			provider: 'amplitude',
			eventName: properties.amplitudeEventName ? properties.amplitudeEventName : this.buildAmplitudeFallbackEventName(eventType, properties),
			eventData: {
				click_value: properties.value ? properties.value : properties.label ? properties.label : '',
				click_type: properties.action ? properties.action : '',
				event_category: 'General'
			}
		};

		if (properties.abTestVersion) {
			(dataLayerEvent.eventData as { abtest_version?: AmplitudeABTestVersion }).abtest_version = properties.abTestVersion.toUpperCase() as AmplitudeABTestVersion;
		}

		return dataLayerEvent;
	}

	buildAmplitudeInteractionEvent(properties: AnalyticsTrack): AmplitudeEventInteraction {
		const eventType = this.buildAmplitudeEventType(properties);
		const valueInteraction = properties.value ? properties.value : properties.label ? properties.label : '';
		const dataLayerEvent = {
			provider: 'amplitude',
			eventName: properties.amplitudeEventName ? properties.amplitudeEventName : this.buildAmplitudeFallbackEventName(eventType, properties),
			eventData: {
				value: valueInteraction,
				action: properties.action ? properties.action : '',
				event_category: 'General'
			}
		};

		if (properties.abTestVersion) {
			(dataLayerEvent.eventData as { abtest_version?: AmplitudeABTestVersion }).abtest_version = properties.abTestVersion.toUpperCase() as AmplitudeABTestVersion;
		}

		return dataLayerEvent;
	}
}
