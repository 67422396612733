import { NgModule } from '@angular/core';
import { DateFnsModule } from '@angular/material-date-fns-adapter';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BundesmasterFeatureMediaLibraryModule } from '@nx-bundesliga/bundesmaster/feature-media-library';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxTributeModule } from '../../../../framework/ngx-tribute/ngx-tribute.module';
import { LivetickerEventsModule, LivetickerEventsPipeModule } from '@nx-bundesliga/bundesliga-com/components/liveticker';
import { BlBasicEventInputComponent } from './basic/bl-basic-event-input.component';
import { BlApesterEventInputComponent } from './apester/bl-apester-event-input.component';
import { BlCardEventInputComponent } from './card/bl-card-event-input.component';
import { BlInstagramEventInputComponent } from './instagram/bl-instagram-event-input.component';
import { BlLineupEventInputComponent } from './lineup/bl-lineup-event-input.component';
import { BlMonterosaEventInputComponent } from './monterosa/bl-monterosa-event-input.component';
import { BlPenaltyEventInputComponent } from './penalty/bl-penalty-event-input.component';
import { BlProgressEventInputComponent } from './progress/bl-progress-event-input.component';
import { BlStatsEventInputComponent } from './stats/bl-stats-event-input.component';
import { BlTwitterEventInputComponent } from './twitter/bl-twitter-event-input.component';
import { BlSubstitutionEventInputComponent } from './substitution/bl-substitution-event-input.component';
import { BlYoutubeEventInputComponent } from './youtube/bl-youtube-event-input.component';
import { BlGoalEventInputComponent } from './goal/bl-goal-event-input.component';
import { BlVideoEventInputComponent } from './video/bl-video-event-input.component';
import { BlFacebookEventInputComponent } from './facebook/bl-facebook-event-input.component';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DflNgxEmbedsModule } from '@nx-bundesliga/bundesliga-com/components/dfl-ngx-embeds';
import { DflNgxLazyLoadModule } from '@nx-bundesliga/shared/dfl-ngx-lazyload';
import { BlImageEventInputComponent } from './image/bl-image-event-input.component';

export const MY_FORMATS = {
	parse: {
		dateInput: 'MM/YYYY'
	},
	display: {
		dateInput: 'MM/YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY'
	}
};

@NgModule({
	declarations: [
		BlApesterEventInputComponent,
		BlBasicEventInputComponent,
		BlLineupEventInputComponent,
		BlCardEventInputComponent,
		BlFacebookEventInputComponent,
		BlGoalEventInputComponent,
		BlPenaltyEventInputComponent,
		BlInstagramEventInputComponent,
		BlVideoEventInputComponent,
		BlSubstitutionEventInputComponent,
		BlProgressEventInputComponent,
		BlTwitterEventInputComponent,
		BlYoutubeEventInputComponent,
		BlMonterosaEventInputComponent,
		BlImageEventInputComponent,
		BlStatsEventInputComponent
	],
	exports: [
		BlApesterEventInputComponent,
		BlBasicEventInputComponent,
		BlLineupEventInputComponent,
		BlCardEventInputComponent,
		BlFacebookEventInputComponent,
		BlGoalEventInputComponent,
		BlPenaltyEventInputComponent,
		BlInstagramEventInputComponent,
		BlVideoEventInputComponent,
		BlSubstitutionEventInputComponent,
		BlProgressEventInputComponent,
		BlTwitterEventInputComponent,
		BlYoutubeEventInputComponent,
		BlMonterosaEventInputComponent,
		BlImageEventInputComponent,
		BlStatsEventInputComponent
	],
	imports: [
		CommonsModule,
		FormsModule,

		LivetickerEventsPipeModule,
		LivetickerEventsModule, // <-- actual live-ticker event card components
		DflNgxEmbedsModule,
		DflNgxLazyLoadModule,

		MatCardModule,

		MatDatepickerModule,
		DateFnsModule,

		MatTabsModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		NgxMatSelectSearchModule,
		MatOptionModule,
		MatButtonModule,
		MatIconModule,
		MatDividerModule,
		MatListModule,

		NgxTributeModule,
		MatProgressSpinnerModule,
		MatCheckboxModule,
		BundesmasterFeatureMediaLibraryModule,
		MatExpansionModule,
		ReactiveFormsModule
	],
	providers: [
		// {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
		// {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
	]
})
export class BlRapidTickerEventsModule {}
