<mat-dialog-content>
	<h2>Edit Slide <small>{{ slideType }}</small></h2>

	<div [formGroup]="form" *ngIf="form">
		<h2> Id: <input type="text" formControlName="slideId" class="slideId-input" tabindex="-1" /></h2>

		<ng-container *ngIf="slideType === slideTypes.TEXT || slideType === slideTypes.VIDEO || slideType === slideTypes.MONTEROSA">
			<!-- Headline -->
			<mat-form-field class="w-100" appearance="outline">
				<mat-label>Headline</mat-label>
				<input matInput type="text" formControlName="headline" />
			</mat-form-field>

			<!-- Text -->
			<mat-form-field class="w-100" appearance="outline">
				<mat-label>Text</mat-label>
				<input matInput type="text" formControlName="text" />
			</mat-form-field>
		</ng-container>

		<ng-container *ngIf="slideType === slideTypes.QUOTE">
			<!-- Quote -->
			<mat-form-field class="w-100" appearance="outline">
				<mat-label>Quote</mat-label>
				<input matInput type="text" formControlName="quote" />
			</mat-form-field>

			<!-- Author -->
			<mat-form-field class="w-100" appearance="outline">
				<mat-label>Author</mat-label>
				<input matInput type="text" formControlName="author" />
			</mat-form-field>
		</ng-container>

		<ng-container *ngIf="slideType === slideTypes.GOAL">
			<mat-form-field class="w-100" appearance="outline">
				<mat-label>Goal Type</mat-label>
				<mat-select formControlName="goalType">
					<mat-option value="goal">goal</mat-option>
					<mat-option value="ownGoal">ownGoal</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-checkbox class="w-100" formControlName="penalty">
				<mat-hint>Is a penalty-shot goal</mat-hint>
			</mat-checkbox>
			<mat-form-field class="w-100" appearance="outline">
				<mat-label>xG</mat-label>
				<input matInput type="number" formControlName="xG" />
			</mat-form-field>
			<mat-form-field class="w-100" appearance="outline">
				<mat-label>distanceToGoal</mat-label>
				<input matInput type="number" formControlName="distanceToGoal" />
			</mat-form-field>
			<mat-form-field class="w-100" appearance="outline">
				<mat-label>shotSpeed</mat-label>
				<input matInput type="number" formControlName="shotSpeed" />
			</mat-form-field>
			<ng-container formGroupName="scorer">
				<hr />
				<h3>Scorer</h3>
				<!-- dflDatalibraryClubId -->
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>dflDatalibraryClubId</mat-label>
					<input matInput type="text" formControlName="dflDatalibraryClubId" />
				</mat-form-field>
				<!-- dflDatalibraryObjectId -->
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>dflDatalibraryObjectId</mat-label>
					<input matInput type="text" formControlName="dflDatalibraryObjectId" />
				</mat-form-field>
				<!-- name -->
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>name</mat-label>
					<input matInput type="text" formControlName="name" />
				</mat-form-field>
				<!-- imageUrl -->
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>imageUrl</mat-label>
					<input matInput type="text" formControlName="imageUrl" />
				</mat-form-field>
			</ng-container>
		</ng-container>

		<ng-container *ngIf="slideType === slideTypes.CARD">
			<mat-form-field class="w-100" appearance="outline">
				<mat-label>Card Type</mat-label>
				<mat-select formControlName="cardType">
					<mat-option value="yellowCard">yellowCard</mat-option>
					<mat-option value="yellowRedCard">yellowRedCard</mat-option>
					<mat-option value="redCard">redCard</mat-option>
				</mat-select>
			</mat-form-field>
			<ng-container formGroupName="person">
				<hr />
				<h3>Player</h3>
				<!-- dflDatalibraryClubId -->
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>dflDatalibraryClubId</mat-label>
					<input matInput type="text" formControlName="dflDatalibraryClubId" />
				</mat-form-field>
				<!-- dflDatalibraryObjectId -->
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>dflDatalibraryObjectId</mat-label>
					<input matInput type="text" formControlName="dflDatalibraryObjectId" />
				</mat-form-field>
				<!-- name -->
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>name</mat-label>
					<input matInput type="text" formControlName="name" />
				</mat-form-field>
				<!-- imageUrl -->
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>imageUrl</mat-label>
					<input matInput type="text" formControlName="imageUrl" />
				</mat-form-field>
			</ng-container>
		</ng-container>

		<ng-container *ngIf="slideType === slideTypes.SUB">
			<ng-container formGroupName="in">
				<hr />
				<!-- dflDatalibraryClubId -->
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>dflDatalibraryClubId</mat-label>
					<input matInput type="text" formControlName="dflDatalibraryClubId" />
				</mat-form-field>
				<hr />
				<h3>Player IN</h3>
				<!-- dflDatalibraryClubId -->
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>dflDatalibraryClubId</mat-label>
					<input matInput type="text" formControlName="dflDatalibraryClubId" />
				</mat-form-field>
				<!-- dflDatalibraryObjectId -->
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>dflDatalibraryObjectId</mat-label>
					<input matInput type="text" formControlName="dflDatalibraryObjectId" />
				</mat-form-field>
				<!-- name -->
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>name</mat-label>
					<input matInput type="text" formControlName="name" />
				</mat-form-field>
				<!-- imageUrl -->
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>imageUrl</mat-label>
					<input matInput type="text" formControlName="imageUrl" />
				</mat-form-field>
			</ng-container>

			<ng-container formGroupName="out">
				<hr />
				<h3>Player OUT</h3>
				<!-- dflDatalibraryClubId -->
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>dflDatalibraryClubId</mat-label>
					<input matInput type="text" formControlName="dflDatalibraryClubId" />
				</mat-form-field>
				<!-- dflDatalibraryObjectId -->
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>dflDatalibraryObjectId</mat-label>
					<input matInput type="text" formControlName="dflDatalibraryObjectId" />
				</mat-form-field>
				<!-- name -->
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>name</mat-label>
					<input matInput type="text" formControlName="name" />
				</mat-form-field>
				<!-- imageUrl -->
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>imageUrl</mat-label>
					<input matInput type="text" formControlName="imageUrl" />
				</mat-form-field>
			</ng-container>
		</ng-container>

		<ng-container *ngIf="slideType === slideTypes.PROGRESS">
			<mat-form-field class="w-100" appearance="outline">
				<mat-label>Progress Type</mat-label>
				<mat-select formControlName="progressType">
					<mat-option value="start_firstHalf">start_firstHalf</mat-option>
					<mat-option value="end_firstHalf">end_firstHalf</mat-option>
					<mat-option value="start_secondHalf">start_secondHalf</mat-option>
					<mat-option value="end_secondHalf">end_secondHalf</mat-option>
					<mat-option value="start_firstHalfExtra">start_firstHalfExtra</mat-option>
					<mat-option value="end_firstHalfExtra">end_firstHalfExtra</mat-option>
					<mat-option value="start_secondHalfExtra">start_secondHalfExtra</mat-option>
					<mat-option value="end_secondHalfExtra">end_secondHalfExtra (use start_penalty or finalWhistle instead)</mat-option>
					<mat-option value="start_penalty">start_penalty</mat-option>
					<mat-option value="end_penalty">end_penalty (use finalWhistle instead)</mat-option>
					<mat-option value="finalWhistle">finalWhistle</mat-option>
				</mat-select>
			</mat-form-field>
		</ng-container>

		<ng-container *ngIf="slideType === slideTypes.LINEUP">
			<!-- dflDatalibraryClubId -->
			<mat-form-field class="w-100" appearance="outline">
				<mat-label>dflDatalibraryClubId</mat-label>
				<input matInput type="text" formControlName="dflDatalibraryClubId" />
			</mat-form-field>
			<hr />
			<ng-container formArrayName="persons">
				<ng-container *ngFor="let lineupPerson of (form.controls['persons'] | asFormArray)?.controls; let lineupPersonIndex = index;" [formGroupName]="lineupPersonIndex">
					<hr />
					<h3>Player {{lineupPersonIndex + 1}}</h3>
					<!-- dflDatalibraryClubId -->
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>dflDatalibraryClubId</mat-label>
						<input matInput type="text" formControlName="dflDatalibraryClubId" />
					</mat-form-field>
					<!-- dflDatalibraryObjectId -->
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>dflDatalibraryObjectId</mat-label>
						<input matInput type="text" formControlName="dflDatalibraryObjectId" />
					</mat-form-field>
					<!-- name -->
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>name</mat-label>
						<input matInput type="text" formControlName="name" />
					</mat-form-field>
					<!-- imageUrl -->
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>imageUrl</mat-label>
						<input matInput type="text" formControlName="imageUrl" />
					</mat-form-field>
					<!-- shirtNumber -->
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>shirtNumber</mat-label>
						<input matInput type="number" formControlName="shirtNumber" />
					</mat-form-field>
				</ng-container>
			</ng-container>
		</ng-container>

		<ng-container formGroupName="score" *ngIf="storyType === 'match_story' && (slideType === slideTypes.PROGRESS || slideType === slideTypes.GOAL)">
			<hr />
			<h3>Score</h3>

			<!-- home -->
			<mat-form-field class="w-100" appearance="outline">
				<mat-label>home</mat-label>
				<input matInput type="number" formControlName="home" />
			</mat-form-field>

			<!-- away -->
			<mat-form-field class="w-100" appearance="outline">
				<mat-label>away</mat-label>
				<input matInput type="number" formControlName="away" />
			</mat-form-field>
		</ng-container>

		<ng-container formGroupName="playtime" *ngIf="storyType === 'match_story'">
			<hr />
			<h3>Playtime</h3>

			<!-- minute -->
			<mat-form-field class="w-100" appearance="outline">
				<mat-label>Minute</mat-label>
				<input matInput type="number" formControlName="minute" />
			</mat-form-field>

			<!-- injuryTime -->
			<mat-form-field class="w-100" appearance="outline">
				<mat-label>InjuryTime</mat-label>
				<input matInput type="number" formControlName="injuryTime" />
			</mat-form-field>
		</ng-container>
	</div>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button mat-flat-button color="warn" (click)="discard()">Discard</button>
	<button mat-flat-button color="primary" [disabled]="!form.valid" (click)="save()">Save</button>
</mat-dialog-actions>
