import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Language } from '@nx-bundesliga/models';
import { getWorkingLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { map } from 'rxjs/operators';

@Component({
	selector: 'livetickerevent-matchfacts-banner',
	templateUrl: './liveticker-event-matchfacts-banner.component.html',
	styleUrls: ['../../../style/liveticker.common.scss', './liveticker-event-matchfacts-banner.component.scss']
})
export class LivetickerEventMatchfactsBannerComponent implements OnInit, OnDestroy {
	@Input() isWebview = false;

	public awsPartnerPageUrl$: Observable<string>;
	private lstoreSubscription: Subscription;

	constructor(private readonly lstore: Store<Language>) {}

	ngOnInit() {
		this.awsPartnerPageUrl$ = this.lstore.pipe(
			select(getWorkingLanguage),
			map((langstate) => {
				const language = langstate.code;
				return language === 'de' ? 'https://www.bundesliga.com/de/bundesliga/route-info/provider/aws' : 'https://www.bundesliga.com/en/bundesliga/route-info/provider/aws';
			})
		);
	}

	ngOnDestroy(): void {
		this.lstoreSubscription?.unsubscribe();
	}
}
