import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DflSimpleIconComponent } from './dfl-simple-icon.component';

@NgModule({
	declarations: [DflSimpleIconComponent],
	exports: [DflSimpleIconComponent],
	imports: [CommonModule, NgOptimizedImage]
})
export class DflSimpleIconModule {}
