<div dflLazyLoad lazyType="embed" (elementLoaded)="isInViewNow()">
	<ng-container *ngIf="shouldBeDisplayed">
		<div *ngIf="embed.embedPlatform === 'youtube'" class="youtube">
			<iframe width="100%" height="100%" [src]="src | safe" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
		</div>

		<div class="wrapper" *ngIf="embed.embedPlatform === 'facebook'">
			<embed-facebook *ngIf="consented || (consents$ | async)?.C0005; else consentBox" [id]="embed.embedId" [forceLoad]="consented" />
			<ng-container *ngIf="consented" [ngTemplateOutlet]="revokeConsent" />
		</div>

		<div class="wrapper" *ngIf="embed.embedPlatform === 'instagram'">
			<embed-instagram *ngIf="consented || (consents$ | async)?.C0005; else consentBox" [id]="embed.embedId" [placeholder]="embed.placeholder" [embedSnipped]="embed.snipped" [forceLoad]="consented" />
			<ng-container *ngIf="consented" [ngTemplateOutlet]="revokeConsent" />
		</div>

		<div class="wrapper" *ngIf="embed.embedPlatform === 'twitter' || embed.embedPlatform === 'x'">
			<embed-tweet *ngIf="consented || (consents$ | async)?.C0005; else consentBox" [id]="embed.embedId" [placeholder]="embed.placeholder" [embedUrl]="embed.url" [forceLoad]="consented" [theme]="theme$ | async" />
			<ng-container *ngIf="consented" [ngTemplateOutlet]="revokeConsent" />
		</div>

		<div class="wrapper" *ngIf="embed.embedPlatform === 'apester' && (embed.url ||  embed.embedId)">
			<embed-apester *ngIf="consented || (consents$ | async)?.C0003; else consentBox" [idOrUrl]="embed.url ? embed.url : embed.embedId" />
			<ng-container *ngIf="consented" [ngTemplateOutlet]="revokeConsent" />
		</div>

		<div *ngIf="embed.embedPlatform === 'monterosa' && embed.eventId">
			<bundesliga-embed-monterosa *ngIf="consented || (consents$ | async)?.C0004; else consentBox" [language]="language$ | async" [embed]="embed" />
			<ng-container *ngIf="consented" [ngTemplateOutlet]="revokeConsent" />
		</div>
	</ng-container>

	<dfl-loader [surrounding]="'small'" [loading]="!shouldBeDisplayed" />
</div>

<ng-template #consentBox>
	<div class="embed-consent">
		<div class="embed-consent-inner">
			<div class="embed-consent-headline">
				<span [innerHTML]="'COOKIE.REC_CONTENT' | translate:{ embedPlatform: embed?.embedPlatform | embedPlatformName } | safeHtml"></span>
				<img #embedIcon [src]="'/assets/icons/social/' + (embed?.embedPlatform | embedPlatformName) + '.svg'" (error)="embedIcon.src = ''" [alt]="embed.embedPlatform ?? 'social-share-icon'" />
			</div>

			<div class="embed-consent-content">
				<p class="large">
					<span [innerHTML]="'COOKIE.DESC' | translate:{ embedPlatform: embed?.embedPlatform | embedPlatformName} | safeHtml"></span>
				</p>

				<p class="bold">
					<mat-slide-toggle (toggleChange)="toggleEmbedConsent()" [checked]="consented">
						<span [innerHTML]="'COOKIE.ACCEPT' | translate:{ embedPlatform: embed?.embedPlatform | embedPlatformName} | safeHtml"></span>
					</mat-slide-toggle>
				</p>

				<p class="faded">
					<span [innerHTML]="'COOKIE.CONSENT' | translate:{ embedPlatform: embed?.embedPlatform | embedPlatformName, embedPrivacyLink: embedPrivacyLink} | safeHtml"></span>
				</p>
				<p *ngIf="!isWebview">
					<a (click)="openOnetrustSettings()" [title]="'COOKIE.EDIT_CONSENT' | translate:{ embedPlatform: embed?.embedPlatform | embedPlatformName}" [innerHTML]="'COOKIE.EDIT_CONSENT' | translate:{ embedPlatform: embed?.embedPlatform | embedPlatformName} | safeHtml"></a>
				</p>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #revokeConsent>
	<p class="revokeConsent" *ngIf="showRevokeConsentToggle === true">
		<mat-slide-toggle (toggleChange)="toggleEmbedConsent()" [checked]="consented">
			<span [innerHTML]="'COOKIE.ACCEPT' | translate:{ embedPlatform: embed?.embedPlatform | embedPlatformName } | safeHtml"></span>
		</mat-slide-toggle>
	</p>
</ng-template>
