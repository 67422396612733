<mat-card appearance="outlined">
	<mat-card-header>
		<div *ngIf="['home', 'away'].includes(this.side) && liveBlogTeams[side].logoUrl" mat-card-avatar class="header-image" [style.background-color]="liveBlogTeams[side].gradientEndColor">
			<!--<img [src]="liveBlogTeams[side]?.logoUrl | imageService:60" alt="" />-->
			<clublogo [dimension]="40" [title]="liveBlogTeams[side].nameFull" [clubId]="liveBlogTeams[side].dflDatalibraryClubId" [clubLogoUrl]="liveBlogTeams[side]?.logoUrl"></clublogo>
		</div>
		<mat-card-title *ngIf="['home', 'away'].includes(this.side)">{{ liveBlogTeams[side].nameFull }}</mat-card-title>
		<div class="btn-start-over" *ngIf="!standalone">
			<button (click)="publish()" tabindex="3" mat-flat-button color="primary">
				<mat-icon>gavel</mat-icon>
				PUBLISH
			</button>
			<button (click)="cancel()" tabindex="7" class="btn-start-over" mat-flat-button>
				<mat-icon>refresh</mat-icon>
				START OVER
			</button>
			<button class="btn-composer-help" mat-flat-button [ngxTippy]="helpTmpl" [tippyProps]="{interactive: true, interactiveBorder: 20, arrow: true, placement: 'bottom'}">
				<mat-icon>info</mat-icon>
			</button>
			<div #helpTmpl class="composer-help">
				<h3>Typeahead in Title and Description</h3>
				<div class="container">
					<div class="row">
						<div class="col-3">&#64;</div>
						<div class="col-9">All Players of this <strong>Match</strong> (Last name only)</div>
					</div>
					<div class="row">
						<div class="col-3">#</div>
						<div class="col-9">All Players of this <strong>Season</strong> (Full name)</div>
					</div>
					<div class="row">
						<div class="col-3"><<</div>
						<div class="col-9">Players and Shirtnumbers of Home Team (<<-- left)</div>
					</div>
					<div class="row">
						<div class="col-3">>></div>
						<div class="col-9">Players and Shirtnumbers of Away Team (-->> right)</div>
					</div>
				</div>
			</div>
		</div>
	</mat-card-header>
	<mat-card-content *ngIf="side">
		<mat-tab-nav-panel #tabPanel>
			<bl-basic-event-input
				*ngIf="isBasicEvent()"
				#eventinputcomp
				[clubData]="liveBlogTeams"
				[matchStatus]="matchStatus"
				[liveEntry]="liveEntry"
				[minuteOfPlay]="minuteOfPlay"
				[language]="language"
				[competitionId]="competitionId"
				[seasonId]="seasonId"
				[headline]="headline"
				[textBody]="textBody"
				[tributeOptions]="tributeOptions"
				[editMode]="editMode"
				[matchdayId]="matchdayId"
				[matchId]="matchId"
				[side]="side"
				[customMatch]="customMatch"></bl-basic-event-input>

			<bl-image-event-input
				*ngIf="isImageEvent()"
				#eventinputcomp
				[clubData]="liveBlogTeams"
				[matchStatus]="matchStatus"
				[liveEntry]="liveEntry"
				[minuteOfPlay]="minuteOfPlay"
				[language]="language"
				[competitionId]="competitionId"
				[seasonId]="seasonId"
				[headline]="headline"
				[textBody]="textBody"
				[tributeOptions]="tributeOptions"
				[editMode]="editMode"
				[matchdayId]="matchdayId"
				[matchId]="matchId"
				[side]="side"
				[customMatch]="customMatch"></bl-image-event-input>

			<bl-jwplayer-event-input
				*ngIf="isVideoEvent()"
				#eventinputcomp
				[clubData]="liveBlogTeams"
				[matchStatus]="matchStatus"
				[liveEntry]="liveEntry"
				[minuteOfPlay]="minuteOfPlay"
				[language]="language"
				[competitionId]="competitionId"
				[seasonId]="seasonId"
				[headline]="headline"
				[textBody]="textBody"
				[tributeOptions]="tributeOptions"
				[editMode]="editMode"
				[matchdayId]="matchdayId"
				[matchId]="matchId"
				[side]="side"
				[customMatch]="customMatch"></bl-jwplayer-event-input>

			<bl-youtube-event-input
				*ngIf="isYoutubeEvent()"
				#eventinputcomp
				[clubData]="liveBlogTeams"
				[matchStatus]="matchStatus"
				[liveEntry]="liveEntry"
				[minuteOfPlay]="minuteOfPlay"
				[language]="language"
				[competitionId]="competitionId"
				[seasonId]="seasonId"
				[headline]="headline"
				[textBody]="textBody"
				[embedId]="embedId"
				[tributeOptions]="tributeOptions"
				[editMode]="editMode"
				[matchdayId]="matchdayId"
				[matchId]="matchId"
				[side]="side"
				[customMatch]="customMatch"></bl-youtube-event-input>

			<bl-twitter-event-input
				*ngIf="isTwitterEvent()"
				#eventinputcomp
				[clubData]="liveBlogTeams"
				[matchStatus]="matchStatus"
				[liveEntry]="liveEntry"
				[minuteOfPlay]="minuteOfPlay"
				[language]="language"
				[competitionId]="competitionId"
				[seasonId]="seasonId"
				[headline]="headline"
				[textBody]="textBody"
				[embedId]="embedId"
				[tributeOptions]="tributeOptions"
				[editMode]="editMode"
				[matchdayId]="matchdayId"
				[matchId]="matchId"
				[side]="side"
				[customMatch]="customMatch"></bl-twitter-event-input>

			<bl-facebook-event-input
				*ngIf="isFacebookEvent()"
				#eventinputcomp
				[clubData]="liveBlogTeams"
				[matchStatus]="matchStatus"
				[liveEntry]="liveEntry"
				[minuteOfPlay]="minuteOfPlay"
				[language]="language"
				[competitionId]="competitionId"
				[seasonId]="seasonId"
				[headline]="headline"
				[textBody]="textBody"
				[embedId]="embedId"
				[tributeOptions]="tributeOptions"
				[editMode]="editMode"
				[matchdayId]="matchdayId"
				[matchId]="matchId"
				[side]="side"
				[customMatch]="customMatch"></bl-facebook-event-input>

			<bl-instagram-event-input
				*ngIf="isInstagramEvent()"
				#eventinputcomp
				[clubData]="liveBlogTeams"
				[matchStatus]="matchStatus"
				[liveEntry]="liveEntry"
				[minuteOfPlay]="minuteOfPlay"
				[language]="language"
				[competitionId]="competitionId"
				[seasonId]="seasonId"
				[headline]="headline"
				[textBody]="textBody"
				[embedId]="embedId"
				[tributeOptions]="tributeOptions"
				[editMode]="editMode"
				[matchdayId]="matchdayId"
				[matchId]="matchId"
				[side]="side"
				[customMatch]="customMatch"></bl-instagram-event-input>

			<bl-apester-event-input
				*ngIf="isApesterEvent()"
				#eventinputcomp
				[clubData]="liveBlogTeams"
				[matchStatus]="matchStatus"
				[liveEntry]="liveEntry"
				[minuteOfPlay]="minuteOfPlay"
				[language]="language"
				[competitionId]="competitionId"
				[seasonId]="seasonId"
				[headline]="headline"
				[textBody]="textBody"
				[embedId]="embedId"
				[tributeOptions]="tributeOptions"
				[editMode]="editMode"
				[matchdayId]="matchdayId"
				[matchId]="matchId"
				[side]="side"
				[customMatch]="customMatch"></bl-apester-event-input>

			<bl-monterosa-event-input
				*ngIf="isMonterosaEvent()"
				#eventinputcomp
				[clubData]="liveBlogTeams"
				[matchStatus]="matchStatus"
				[liveEntry]="liveEntry"
				[minuteOfPlay]="minuteOfPlay"
				[language]="language"
				[competitionId]="competitionId"
				[seasonId]="seasonId"
				[headline]="headline"
				[textBody]="textBody"
				[embedId]="embedId"
				[tributeOptions]="tributeOptions"
				[editMode]="editMode"
				[matchdayId]="matchdayId"
				[matchId]="matchId"
				[side]="side"
				[customMatch]="customMatch"></bl-monterosa-event-input>

			<bl-stats-event-input
				*ngIf="isStatsEvent()"
				#eventinputcomp
				[clubData]="liveBlogTeams"
				[matchStatus]="matchStatus"
				[liveEntry]="liveEntry"
				[minuteOfPlay]="minuteOfPlay"
				[language]="language"
				[competitionId]="competitionId"
				[seasonId]="seasonId"
				[headline]="headline"
				[textBody]="textBody"
				[tributeOptions]="tributeOptions"
				[editMode]="editMode"
				[matchdayId]="matchdayId"
				[matchId]="matchId"
				[side]="side"
				[customMatch]="customMatch"></bl-stats-event-input>

			<bl-goal-event-input
				*ngIf="isGoalEvent()"
				#eventinputcomp
				[clubData]="liveBlogTeams"
				[matchStatus]="matchStatus"
				[liveEntry]="liveEntry"
				[minuteOfPlay]="minuteOfPlay"
				[language]="language"
				[competitionId]="competitionId"
				[seasonId]="seasonId"
				[tributeOptions]="tributeOptions"
				[editMode]="editMode"
				[matchdayId]="matchdayId"
				[matchId]="matchId"
				[side]="side"
				[customMatch]="customMatch"></bl-goal-event-input>

			<bl-substitution-event-input
				*ngIf="isSubstitutionEvent()"
				#eventinputcomp
				[clubData]="liveBlogTeams"
				[matchStatus]="matchStatus"
				[liveEntry]="liveEntry"
				[minuteOfPlay]="minuteOfPlay"
				[language]="language"
				[competitionId]="competitionId"
				[seasonId]="seasonId"
				[tributeOptions]="tributeOptions"
				[editMode]="editMode"
				[matchdayId]="matchdayId"
				[matchId]="matchId"
				[side]="side"
				[customMatch]="customMatch"></bl-substitution-event-input>

			<bl-card-event-input
				*ngIf="isCardEvent()"
				#eventinputcomp
				[clubData]="liveBlogTeams"
				[matchStatus]="matchStatus"
				[liveEntry]="liveEntry"
				[minuteOfPlay]="minuteOfPlay"
				[language]="language"
				[competitionId]="competitionId"
				[seasonId]="seasonId"
				[tributeOptions]="tributeOptions"
				[editMode]="editMode"
				[matchdayId]="matchdayId"
				[matchId]="matchId"
				[side]="side"
				[customMatch]="customMatch"></bl-card-event-input>

			<bl-penalty-event-input
				*ngIf="isPenaltyEvent()"
				#eventinputcomp
				[clubData]="liveBlogTeams"
				[matchStatus]="matchStatus"
				[liveEntry]="liveEntry"
				[minuteOfPlay]="minuteOfPlay"
				[language]="language"
				[competitionId]="competitionId"
				[seasonId]="seasonId"
				[tributeOptions]="tributeOptions"
				[editMode]="editMode"
				[matchdayId]="matchdayId"
				[matchId]="matchId"
				[side]="side"
				[customMatch]="customMatch"></bl-penalty-event-input>

			<bl-progress-event-input
				*ngIf="isProgressEvent()"
				#eventinputcomp
				[clubData]="liveBlogTeams"
				[matchStatus]="matchStatus"
				[liveEntry]="liveEntry"
				[minuteOfPlay]="minuteOfPlay"
				[language]="language"
				[competitionId]="competitionId"
				[seasonId]="seasonId"
				[tributeOptions]="tributeOptions"
				[editMode]="editMode"
				[matchdayId]="matchdayId"
				[matchId]="matchId"
				[side]="side"
				[customMatch]="customMatch"></bl-progress-event-input>

			<bl-lineup-event-input
				*ngIf="isLineupEvent()"
				#eventinputcomp
				[clubData]="liveBlogTeams"
				[matchStatus]="matchStatus"
				[liveEntry]="liveEntry"
				[minuteOfPlay]="minuteOfPlay"
				[language]="language"
				[competitionId]="competitionId"
				[seasonId]="seasonId"
				[tributeOptions]="tributeOptions"
				[editMode]="editMode"
				[matchdayId]="matchdayId"
				[matchId]="matchId"
				[side]="side"
				[customMatch]="customMatch"></bl-lineup-event-input>
		</mat-tab-nav-panel>
		<nav mat-tab-nav-bar [tabPanel]="tabPanel" class="rapid-ticker-tabbar">
			<a mat-tab-link *ngIf="hideOtherEntryTypes === false || (hideOtherEntryTypes === true && isBasicEvent())" [active]="isBasicEvent()" (click)="setBasicEvent()">
				<mat-icon>text_format</mat-icon>
				Text
			</a>
			<ng-container *ngIf="customMatch">
				<a mat-tab-link *ngIf="(hideOtherEntryTypes === false || (hideOtherEntryTypes === true && isGoalEvent())) && side !== 'none'" [active]="isGoalEvent()" (click)="setGoalEvent()">
					<mat-icon>sports_soccer</mat-icon>
					Goal
				</a>
				<a mat-tab-link *ngIf="(hideOtherEntryTypes === false || (hideOtherEntryTypes === true && isCardEvent())) && side !== 'none'" [active]="isCardEvent()" (click)="setCardEvent()">
					<mat-icon>class</mat-icon>
					Card
				</a>
				<a mat-tab-link *ngIf="(hideOtherEntryTypes === false || (hideOtherEntryTypes === true && isSubstitutionEvent())) && side !== 'none'" [active]="isSubstitutionEvent()" (click)="setSubstitutionEvent()">
					<mat-icon>import_export</mat-icon>
					Sub
				</a>
				<a mat-tab-link *ngIf="(hideOtherEntryTypes === false || (hideOtherEntryTypes === true && isPenaltyEvent())) && side !== 'none'" [active]="isPenaltyEvent()" (click)="setPenaltyEvent()">
					<mat-icon>sports_soccer</mat-icon>
					Penalty
				</a>
				<a mat-tab-link *ngIf="(hideOtherEntryTypes === false || (hideOtherEntryTypes === true && isProgressEvent())) && side === 'none'" [active]="isProgressEvent()" (click)="setProgressEvent()">
					<mat-icon>tour</mat-icon>
					Progress
				</a>
				<a mat-tab-link *ngIf="(hideOtherEntryTypes === false || (hideOtherEntryTypes === true && isLineupEvent())) && side !== 'none'" [active]="isLineupEvent()" (click)="setLineupEvent()">
					<mat-icon>tour</mat-icon>
					Lineup
				</a>
			</ng-container>
			<a mat-tab-link *ngIf="hideOtherEntryTypes === false || (hideOtherEntryTypes === true && isImageEvent())" [active]="isImageEvent()" (click)="setImageEvent()">
				<mat-icon>landscape</mat-icon>
				Image
			</a>
			<a mat-tab-link *ngIf="hideOtherEntryTypes === false || (hideOtherEntryTypes === true && isVideoEvent())" [active]="isVideoEvent()" (click)="setVideoEvent()">
				<mat-icon>ondemand_video</mat-icon>
				Video
			</a>
			<a mat-tab-link *ngIf="hideOtherEntryTypes === false || (hideOtherEntryTypes === true && isYoutubeEvent())" [active]="isYoutubeEvent()" (click)="setYoutubeEvent()">
				<mat-icon>ondemand_video</mat-icon>
				YouTube
			</a>
			<a mat-tab-link *ngIf="hideOtherEntryTypes === false || (hideOtherEntryTypes === true && isTwitterEvent())" [active]="isTwitterEvent()" (click)="setTwitterEvent()">
				<mat-icon>attach_file</mat-icon>
				Twitter
			</a>
			<a mat-tab-link *ngIf="(hideOtherEntryTypes === true && isFacebookEvent())" [active]="isFacebookEvent()" (click)="setFacebookEvent()">
				<mat-icon>attach_file</mat-icon>
				Facebook
			</a>
			<a mat-tab-link *ngIf="(hideOtherEntryTypes === true && isInstagramEvent())" [active]="isInstagramEvent()" (click)="setInstagramEvent()">
				<mat-icon>attach_file</mat-icon>
				Instagram
			</a>
			<a mat-tab-link *ngIf="hideOtherEntryTypes === false || (hideOtherEntryTypes === true && isApesterEvent())" [active]="isApesterEvent()" (click)="setApesterEvent()">
				<mat-icon>attach_file</mat-icon>
				Apester
			</a>
			<a mat-tab-link *ngIf="hideOtherEntryTypes === false || (hideOtherEntryTypes === true && isMonterosaEvent())" [active]="isMonterosaEvent()" (click)="setMonterosaEvent()">
				<mat-icon>attach_file</mat-icon>
				Monterosa
			</a>
			<a mat-tab-link *ngIf="hideOtherEntryTypes === false || (hideOtherEntryTypes === true && isStatsEvent())" [active]="isStatsEvent()" (click)="setStatsEvent()">
				<mat-icon>bar_chart</mat-icon>
				Stats
			</a>
		</nav>
	</mat-card-content>
</mat-card>
