import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';
import { ScriptLoaderService } from '@nx-bundesliga/bundesliga-com/services/script-loader';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';

@Injectable()
export class AnalyticsScriptsLoaderService {
	private renderer: Renderer2;
	constructor(private readonly config: ConfigService, private loader: ScriptLoaderService, private rendererFactory: RendererFactory2, @Inject(WINDOW) private win: Window, @Inject(PLATFORM_ID) private readonly platformId: any) {
		const isWebview = this.config.getSettings('webview', false);
		let gtag = this.config.getSettings('analytics.gtag');
		if (isWebview) {
			gtag = this.config.getSettings('analytics.gtag-webview-accounts');
		}

		this.loader.setConfigKey(gtag, 'gtagId', false);

		// load script in head
		this.loader.load('gtag')[0].subscribe(
			() => {},
			(error) => {
				console.warn(`gtag.loader.error: ${error}`);
			}
		);

		// load noscript iframe for body
		if (isPlatformBrowser(this.platformId)) {
			this.renderer = rendererFactory.createRenderer(null, null);
			const gtagUrl = 'https://www.googletagmanager.com/ns.html?id=' + gtag;
			const gtagNoscript = this.renderer.createElement('noscript');
			const iframe = this.renderer.createElement('iframe');

			this.renderer.setProperty(iframe, 'height', '0');
			this.renderer.setProperty(iframe, 'width', '0');
			this.renderer.setStyle(iframe, 'display', 'none');
			this.renderer.setStyle(iframe, 'visibility', 'hidden');
			this.renderer.setProperty(iframe, 'src', gtagUrl);
			this.renderer.appendChild(gtagNoscript, iframe);

			win.document.body.appendChild(gtagNoscript);
		}
	}
}
