// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: any = {
	production: false,
	stage: 'development',
	domain: 'https://www.bundesliga-dev.com',
	liveBlogEntries: 'liveBlogEntries',
	stageDomain: 'bundesliga-dev',
	instana: { key: 'Tbq_NaJrQkGAtK-g1BwhbQ', reportingUrl: 'https://eum-blue-saas.instana.io' },
	system: {
		'applicationName': 'SEO.APP_NAME',
		'applicationUrl': 'https://www.bundesliga-dev.com'
	},
	msal: {
		stage: 'development',
		stageDomain: 'bundesliga-dev',
		firebaseDomain: 'bundesliga-web-dev',
		firebaseDomainMobile: 'bundesliga-app-official-dev',
		dfldsApiKey: 'web-1-oiUh-DEV',
		loginUrl: 'https://bundesstreaker.bundesliga-dev.com/login',
		logoutUrl: 'https://bundesstreaker.bundesliga-dev.com/logout',
		clientId: '9a6ce631-e299-4ecd-bc0e-7efa3d610592',
		authority: '89a6caa2-899f-4ef6-8e7f-c4c2d2a4220f',
		contender: {
			clientId: 'b9865e8d-3dcc-4f8d-9580-fb9dc861107e'
		}
	},
	firebase: {
		apiKey: 'AIzaSyDPBrA4jejuIT_d5Lm6N6TXE-cqp5o2Hv8',
		authDomain: 'bundesliga-web-dev.firebaseapp.com',
		databaseURL: 'https://bundesliga-web-dev.europe-west1.firebasedatabase.app',
		projectId: 'bundesliga-web-official',
		storageBucket: 'bundesliga-web-official.appspot.com',
		messagingSenderId: '734520686470',
		appId: '1:734520686470:web:1b9eff516436e2be519e07'
	},
	gmaps: {
		key: 'AIzaSyCkHcIo_UOxd80r6HOKTGqqT0g8WEKoYuk'
	},
	jw: {
		playlist: {
			goals: {
				dach: 'qGuv3ENY',
				nondach: 'ZosXLSkY'
			},
			player: 'ReJQNyhH'
		}
	},
	facebook: {
		appId: '397112304023421'
	},
	monterosa: {
		projectId: 'df064702-aa70-4438-a5c4-e2fda7a9f70f'
	},
	apis: {
		ai: {
			url: 'https://ai.api.bundesmaster.bundesliga-dev.com'
		},
		assets: {
			url: 'https://assets.bundesliga-dev.com'
		},
		stories: {
			url: 'https://stories.api.bundesmaster.bundesliga-dev.com'
		},
		proxy: {
			url: 'https://api.bundesmaster.bundesliga-dev.com'
		},
		content: {
			url: 'https://content-api.bundesmaster.bundesliga-dev.com'
		},
		wapp: {
			url: 'https://wapp.bapi.bundesliga-dev.com',
			key: 'XPU9UxAzKh0396AU-DEV'
		}
	},
	videoLibrary: {
		jwPlayerId: '3qIK4vrl'
	},
	endpoints: {
		config: {
			uri: 'https://bundesliga-web-dev.europe-west1.firebasedatabase.app/config.json',
			base: 'https://bundesliga-web-dev.europe-west1.firebasedatabase.app'
		},

		defaults: {
			key: 'XPU9UxAzKh0396AU-DEV',
			base: 'https://wapp.bapi.bundesliga-dev.com',
			header: 'x-api-key'
		},
		club: {
			path: 'club'
		},

		person: {
			path: 'person'
		},

		player: {
			path: 'player'
		},

		broadcaster: {
			path: 'broadcaster'
		},

		broadcasters: {
			path: 'broadcasters'
		},

		editorial: {
			path: 'editorial'
		},

		wiki: {
			path: 'editorial/wiki'
		},

		broadcasts: {
			path: 'broadcasts'
		},

		cdnLocale: {
			path: 'web-api/locale'
		},

		migration: {
			s3: 'https://migration-dfl-dev.s3.amazonaws.com',
			cdn: 'https://s.bundesliga-dev.com'
		}
	},
	i18n: {
		defaultLanguage: {
			code: 'de',
			name: 'German',
			localName: 'Deutsch',
			culture: 'de-DE',
			ietf: 'de_DE'
		},
		availableLanguages: [
			{
				code: 'en',
				name: 'English',
				localName: 'English',
				culture: 'en-EN',
				ietf: 'en_US'
			},
			{
				code: 'de',
				name: 'German',
				localName: 'Deutsch',
				culture: 'de-DE',
				ietf: 'de_DE'
			},
			{
				code: 'es',
				name: 'Spanish',
				localName: 'Español',
				culture: 'es-ES',
				ietf: 'es_ES'
			},
			{
				code: 'jp',
				name: 'Japanese',
				localName: '日本語',
				culture: 'ja-JP',
				ietf: 'ja_JP'
			},
			{
				code: 'fr',
				name: 'French',
				localName: 'Français',
				culture: 'fr-FR',
				ietf: 'fr_FR'
			},
			{
				code: 'pt',
				name: 'Portuguese',
				localName: 'Portuguese',
				culture: 'pt-PT',
				ietf: 'pt_PT'
			},
			{
				code: 'ar',
				name: 'Arabic',
				localName: 'Arabic',
				culture: 'ar-AR',
				ietf: 'ar_AR'
			}
		]
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
