import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BundesligaMatchStates, LiveBlogAutoEvents, LiveBlogClubs, LiveBlogInfos, LivetickerSides, Playtime } from '@nx-bundesliga/models';
import { take } from 'rxjs/operators';
import { IsTypingService } from '../../../../services/liveticker-backend/is-typing.service';
import { TributeOptionsService } from '../../../../services/tribute-options/tribute-options.service';

@Component({
	selector: 'bl-rapid-ticker-composer',
	templateUrl: './bl-rapid-ticker-composer.component.html',
	styleUrls: ['./bl-rapid-ticker-composer.component.scss']
})
export class BlRapidTickerComposerComponent implements OnInit, OnChanges {
	@Output() composed: EventEmitter<any> = new EventEmitter<any>();
	@Output() canceled: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('eventinputcomp') activeInputComponent: Component;

	@Input() liveBlogInfos: LiveBlogInfos;
	@Input() liveBlogTeams: LiveBlogClubs;
	@Input() minuteOfPlay: Playtime;
	@Input() matchStatus: BundesligaMatchStates;
	@Input() side: LivetickerSides = 'none';
	@Input() language: string;
	@Input() competitionId: string;
	@Input() seasonId: string;
	@Input() matchdayId: string;
	@Input() matchId: string;
	@Input() customMatch = false;
	@Input() liveEntry: LiveBlogAutoEvents;
	@Input() editMode = false;
	@Input() standalone = false;
	@Input() hideOtherEntryTypes = false;
	@Input() embedId: string;
	public entryType = 'freetext';
	public headline = '';
	public textBody = '';
	public embedPlatform = '';
	public tributeOptions;

	constructor(private tributeOptionsService: TributeOptionsService, private typingService: IsTypingService) {}

	ngOnInit(): void {
		if (this.liveEntry) {
			this.headline = this.liveEntry.detail.hasOwnProperty('headline') ? this.liveEntry.detail.headline : '';
			this.textBody = this.liveEntry.detail.hasOwnProperty('text') ? this.liveEntry.detail.text : '';
			this.entryType = this.liveEntry.entryType;
			this.embedPlatform = this.liveEntry.entryType === 'embed' && this.liveEntry.detail.hasOwnProperty('embedPlatform') ? this.liveEntry.detail.embedPlatform : '';
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		// refresh typeahead params after choosing a new match
		if (changes.hasOwnProperty('liveBlogTeams')) {
			this.tributeOptionsService
				.getTributeOptions(this.liveBlogTeams.home.dflDatalibraryClubId, this.liveBlogTeams.away.dflDatalibraryClubId, this.competitionId, this.seasonId)
				.pipe(take(1))
				.subscribe((tOptions) => {
					this.tributeOptions = tOptions;
				});
		}
	}

	updateHeadLineAndText() {
		const currentLiveEntry = (this.activeInputComponent as any).getPayload() as any;
		this.headline = currentLiveEntry.detail.hasOwnProperty('headline') ? currentLiveEntry.detail.headline : '';
		this.textBody = currentLiveEntry.detail.hasOwnProperty('text') ? currentLiveEntry.detail.text : '';
	}

	public publish() {
		const payload = {
			...(this.activeInputComponent as any).getPayload()
		};
		this.composed.emit(payload);
		this.resetCachedValues();
	}

	public cancel() {
		this.canceled.emit();
		this.resetIsTyping(this.side, false);
		this.resetCachedValues();
	}

	private resetIsTyping(side: LivetickerSides, status: boolean) {
		const payload = {
			language: this.language,
			competitionId: this.competitionId,
			seasonId: this.seasonId,
			matchdayId: this.matchdayId,
			matchId: this.matchId
		};

		this.typingService.send(side, status, payload);
	}

	private resetCachedValues() {
		this.headline = '';
		this.textBody = '';
		this.entryType = 'freetext';
		this.embedPlatform = '';
	}

	public getPayload() {
		return {
			...(this.activeInputComponent as any).getPayload()
		};
	}

	isProgressEvent() {
		const progressEvents = [
			'start_firstHalf',
			'end_firstHalf',
			'start_secondHalf',
			'finalWhistle',
			'end_secondHalf',
			'start_firstHalfExtra',
			'end_firstHalfExtra',
			'start_secondHalfExtra',
			'end_secondHalfExtra' /* after extra time ends, penaltys hootout or final whistle is displayed */,
			'start_penalty',
			'end_penalty' /* after penalty shoot out, the final whistle is shown, thats enough*/
		];
		return Object.values(progressEvents).includes(this.entryType);
	}

	isCardEvent() {
		return ['yellowCard', 'yellowRedCard', 'redCard'].includes(this.entryType);
	}

	isGoalEvent() {
		return ['goal', 'ownGoal'].includes(this.entryType);
	}

	isPenaltyEvent() {
		return ['penalty'].includes(this.entryType);
	}

	isLineupEvent() {
		return this.entryType === 'lineup';
	}

	isSubstitutionEvent() {
		return this.entryType === 'sub';
	}

	isBasicEvent() {
		return this.entryType === 'freetext';
	}

	isImageEvent() {
		return this.entryType === 'image';
	}

	isEmbedEvent() {
		return this.entryType === 'embed';
	}

	isYoutubeEvent() {
		return this.isEmbedEvent() && this.embedPlatform === 'youtube';
	}
	isApesterEvent() {
		return this.isEmbedEvent() && this.embedPlatform === 'apester';
	}
	isFacebookEvent() {
		return this.isEmbedEvent() && this.embedPlatform === 'facebook';
	}
	isTwitterEvent() {
		return this.isEmbedEvent() && this.embedPlatform === 'twitter';
	}
	isInstagramEvent() {
		return this.isEmbedEvent() && this.embedPlatform === 'instagram';
	}
	isMonterosaEvent() {
		return this.isEmbedEvent() && this.embedPlatform === 'monterosa';
	}
	isVideoEvent() {
		return this.entryType === 'video';
	}
	isStatsEvent() {
		return this.entryType === 'stats';
	}

	/*
	 * Setter for event types
	 *
	 */

	setProgressEvent() {
		this.updateHeadLineAndText();
		this.entryType = 'start_firstHalf';
		this.embedPlatform = '';
	}

	setLineupEvent() {
		this.updateHeadLineAndText();
		this.entryType = 'lineup';
		this.embedPlatform = '';
	}

	setCardEvent() {
		this.updateHeadLineAndText();
		this.entryType = 'yellowCard';
		this.embedPlatform = '';
	}

	setGoalEvent() {
		this.updateHeadLineAndText();
		this.entryType = 'goal';
		this.embedPlatform = '';
	}

	setPenaltyEvent() {
		this.updateHeadLineAndText();
		this.entryType = 'penalty';
		this.embedPlatform = '';
	}

	setSubstitutionEvent() {
		this.updateHeadLineAndText();
		this.entryType = 'sub';
		this.embedPlatform = '';
	}

	setBasicEvent() {
		this.updateHeadLineAndText();
		this.entryType = 'freetext';
		this.embedPlatform = '';
	}

	setImageEvent() {
		this.updateHeadLineAndText();
		this.entryType = 'image';
		this.embedPlatform = '';
	}

	setYoutubeEvent() {
		this.updateHeadLineAndText();
		this.entryType = 'embed';
		this.embedPlatform = 'youtube';
	}
	setApesterEvent() {
		this.updateHeadLineAndText();
		this.entryType = 'embed';
		this.embedPlatform = 'apester';
	}
	setMonterosaEvent() {
		this.updateHeadLineAndText();
		this.entryType = 'embed';
		this.embedPlatform = 'monterosa';
	}
	setFacebookEvent() {
		this.updateHeadLineAndText();
		this.entryType = 'embed';
		this.embedPlatform = 'facebook';
	}
	setTwitterEvent() {
		this.updateHeadLineAndText();
		this.entryType = 'embed';
		this.embedPlatform = 'twitter';
	}
	setInstagramEvent() {
		this.updateHeadLineAndText();
		this.entryType = 'embed';
		this.embedPlatform = 'instagram';
	}
	setVideoEvent() {
		this.updateHeadLineAndText();
		this.entryType = 'video';
		this.embedPlatform = '';
	}
	setStatsEvent() {
		this.updateHeadLineAndText();
		this.entryType = 'stats';
		this.embedPlatform = '';
	}
}
