import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BundesmasterEnvironment, CORE_ENVIRONMENT } from '@nx-bundesliga/bundesmaster/core';
import { Observable, of } from 'rxjs';

@Component({
	selector: 'monterosa-dialog-select',
	standalone: true,
	imports: [CommonModule, MatIconModule, MatButtonModule, MatInputModule, MatDialogModule, ReactiveFormsModule],
	templateUrl: './monterosa-select.component.html',
	styleUrl: './monterosa-select.component.scss'
})
export class MonterosaSelectComponent {
	public monterosaEvents$: Observable<any>;
	constructor(public dialogRef: MatDialogRef<MonterosaSelectComponent>, @Inject(MAT_DIALOG_DATA) public data: { projectId: string }, private http: HttpClient, @Inject(CORE_ENVIRONMENT) private readonly environment: BundesmasterEnvironment) {
		const projectId = this.data?.projectId || this.environment?.monterosa?.projectId;
		this.monterosaEvents$ = projectId ? this.http.get(`https://cdn.monterosa.cloud/projects/${projectId?.substring(0, 2)}/${projectId}/listings.json`) : of({});
	}
	public experienceSelected(monterosaEvent: string) {
		this.dialogRef.close(monterosaEvent);
	}
}
