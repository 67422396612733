import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Optional, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '@nx-bundesliga/bundesliga-com/framework/analytics';
import { getDurationISO8601, getISO8601DateFromTimestamp } from '@nx-bundesliga/bundesliga-com/framework/common';
import { APP_ENVIRONMENT } from '@nx-bundesliga/bundesliga-com/framework/core';
import { getWorkingLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { ScriptLoaderService } from '@nx-bundesliga/bundesliga-com/services/script-loader';
import { ImageServicePipe } from '@nx-bundesliga/commons';
import { BundesligaJsonLdVideo, ComponentLoadingState, EditorialVideo, JWAdConfig, JWAdSchedule, JWManifest, JWPlayer, JWPlaylist, JWVideo, Language, PartialAnalyticsEvent } from '@nx-bundesliga/models';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { getStageDomain } from '@nx-bundesliga/shared/util/functions';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { share, take, tap } from 'rxjs/operators';

@Component({
	selector: 'dfl-jw-player',
	templateUrl: './dfl-jw-player.component.html',
	styleUrls: ['./dfl-jw-player.component.scss']
})
export class DflJwPlayerComponent implements OnInit, OnDestroy {
	@Input() video: EditorialVideo;
	@Input() tracking: boolean;
	@Input() showHeadline: boolean;
	@Input() showDescription = true;
	@Input() ads = false;
	@Input() autoplay = false;
	@Input() fullscreen = false;
	@Input() autoplaytimer = 5;
	@Input() playlistIndex = 0;
	@Input() playlist: JWPlaylist[] = null;
	@Input() mute = false;
	@Input() lazyLoad = true;
	@Input() loadManifest = false;
	@Input() autoplayNextVideo = true;
	@Input() showJsonLd = true;
	@ViewChild('player', { static: false }) player: ElementRef;
	@Output() manifest: EventEmitter<any> = new EventEmitter<any>(true);
	@Output() public playerState: EventEmitter<ComponentLoadingState> = new EventEmitter<ComponentLoadingState>(true);
	@Output() playlistIndexChange: EventEmitter<number> = new EventEmitter<number>(true);
	@Output() videoChangedEvent: EventEmitter<JWVideo> = new EventEmitter<JWVideo>(true);

	private jw: JWPlayer;
	private readonly isBrowser: boolean;
	// private adTagTest = 'https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/ad_rule_samples&ciu_szs=300x250&ad_rule=1&impl=s&gdfp_req=1&env=vp&output=vmap&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ar%3Dpreonly&cmsid=496&vid=short_onecue&correlator=';

	public styles: any;
	public jsonLd = {};
	public jwState: ComponentLoadingState = ComponentLoadingState.PENDING;
	public elementRef: ElementRef;
	public videoplayerId = 'FX1G0VWK';
	public videoPosterStill = '';
	public language = 'en';
	public readonly errorImage = '/assets/icons/error-states/error-state-empty.svg';
	public readonly defaultImage = '/assets/placeholder/resp_live_placeholder_image_home.jpg';
	public jwPlaylistUrls = {
		'en': 'https://cdn.jwplayer.com/v2/playlists/erHo3VCf?related_media_id=',
		'de': 'https://cdn.jwplayer.com/v2/playlists/UDcrhYES?related_media_id=',
		'es': 'https://cdn.jwplayer.com/v2/playlists/DqLA38SJ?related_media_id=',
		'fr': 'https://cdn.jwplayer.com/v2/playlists/Tv5WnEVO?related_media_id=',
		'jp': 'https://cdn.jwplayer.com/v2/playlists/ZYtqBDKy?related_media_id=',
		'ar': 'https://cdn.jwplayer.com/v2/playlists/O3y3Nb7w?related_media_id=',
		'pt': 'https://cdn.jwplayer.com/v2/playlists/9Fdrlp7F?related_media_id='
	};

	public thumbailHovered = false;
	public enableAnimatedThumbail = false;
	public videoPosterAnimated = '';

	public defaultAdUrlAllLanugages = 'https://pubads.g.doubleclick.net/gampad/ads?iu=/66185244/dfp_bl_web_en/player&description_url=http%3A%2F%2Fwww.bundesliga.com&tfcd=0&npa=0&sz=640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=&nofb=1';
	public jwPlaylistAdSchedules = {
		'de': [
			{
				'adTag': 'https://pubads.g.doubleclick.net/gampad/ads?iu=/66185244/dfp_bl_web_de/player&description_url=http%3A%2F%2Fwww.bundesliga.com&tfcd=0&npa=0&sz=640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=&nofb=1'
			}
		],
		'en': [
			{
				'adTag': this.defaultAdUrlAllLanugages
			}
		],
		'es': [
			{
				'adTag': this.defaultAdUrlAllLanugages
			}
		],
		'fr': [
			{
				'adTag': this.defaultAdUrlAllLanugages
			}
		],
		'ja': [
			{
				'adTag': this.defaultAdUrlAllLanugages
			}
		],
		'ar': [
			{
				'adTag': this.defaultAdUrlAllLanugages
			}
		],
		'pt': [
			{
				'adTag': this.defaultAdUrlAllLanugages
			}
		]
	} as JWAdConfig;

	public defaultAdTestUrl = 'https://pubads.g.doubleclick.net/gampad/ads?iu=/66185244/test_dfp_bl_web_de/test_player&description_url=http%3A%2F%2Fwww.bundesliga.com&tfcd=0&npa=0&sz=640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=&nofb=1';
	public jwPlaylistAdSchedulesDevelopment = {
		'en': [
			{
				'adTag': this.defaultAdTestUrl
			}
		],
		'de': [
			{
				'adTag': this.defaultAdTestUrl
			}
		],
		'es': [
			{
				'adTag': this.defaultAdTestUrl
			}
		],
		'fr': [
			{
				'adTag': this.defaultAdTestUrl
			}
		],
		'ja': [
			{
				'adTag': this.defaultAdTestUrl
			}
		],
		'ar': [
			{
				'adTag': this.defaultAdTestUrl
			}
		],
		'pt': [
			{
				'adTag': this.defaultAdTestUrl
			}
		]
	} as JWAdConfig;

	private manifestSubscription: Subscription;
	private jwPlayerSubscription: Subscription;
	private lStoreSubscription: Subscription;

	constructor(
		@Optional() @Inject(APP_ENVIRONMENT) private readonly environment: any,
		@Inject(PLATFORM_ID) platformId: Object,
		@Inject(ElementRef) elementRef: ElementRef,
		private loader: ScriptLoaderService,
		private http: HttpClient,
		private analyticsService: AnalyticsService,
		public translate: TranslateService,
		private ngZone: NgZone,
		private readonly languageStore: Store<Language>,
		private cdRef: ChangeDetectorRef,
		private readonly router: Router,
		private readonly configService: ConfigService,
		private imageService: ImageServicePipe
	) {
		this.isBrowser = isPlatformBrowser(platformId);
		this.elementRef = elementRef;
	}

	ngOnInit() {
		this.lStoreSubscription = this.languageStore.pipe(select(getWorkingLanguage)).subscribe((language: Language) => {
			this.language = language.code;
			this.autoplay = this._isMobileSafari() ? false : this.autoplay;
			this.setVideoPoster();

			// only videos which were upload later that xx.xx.2021 have video thumbs
			if (this.video.published && this.video.published >= 1617228000) {
				// 1617228000 : 01.04.2021
				this.enableAnimatedThumbail = true;
			}

			this.jsonLd = this.getJsonLd(); // first try via cis data
			// only load jw player directly if lazyLoad is disabled
			if (this.lazyLoad === false) {
				this.initalizeJWPlayer(this.autoplay);
			}
			if (this.loadManifest === true) {
				this.manifestSubscription = this.getManifest(this.video.videoId).subscribe();
			}
			this.playerState.emit(this.jwState);
		});
	}

	/**
	 * checks if users browser is safari and device is apple mobile
	 */
	private _isMobileSafari(): boolean {
		if (this.isBrowser) {
			return !!(navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/));
		} else {
			return false;
		}
	}

	/**
	 * builds the poster / thumbnail of the current video
	 */
	public setVideoPoster(): void {
		if (this.video?.videoId) {
			this.videoPosterStill = `https://assets.${getStageDomain(this.environment?.stage ?? 'prod')}/video/jw/${this.video.videoId}.jpg`;
			if (this.video.hasOwnProperty('poster') && this.video.poster !== '') {
				this.videoPosterStill = this.video.poster;
			}
			this.videoPosterAnimated = `https://cdn.jwplayer.com/thumbs/${this.video.videoId}-640.mp4`;
		}
	}

	/**
	 * Sets up jw player according to {video} and some global params.
	 * Should never be called directly.
	 */
	initalizeJWPlayer(autoplay = false): void {
		this.jwState = ComponentLoadingState.LOADING;
		this.playerState.emit(this.jwState);

		// This call could be moved inside the isBrowser statement to decrease the size of our transferState.
		// It probably wouldn't hurt user experience, since we need to request the player SDK on the client anyway.
		const manifestPromise = this.getManifest(this.video.videoId);
		this.loader.jwPlayerId = this.videoplayerId;

		const loadKeyName = 'jw-cloud-default';
		if (this.video && this.video.skinId && this.video.skinId !== '') {
			// TODO: Might be reused at a later date
			// loadKeyName = 'jw-cloud-schedule-annoucement';
		} else {
			this.video.skinId = this.loader.jwPlayerId;
		}
		if (this.isBrowser) {
			this.jwPlayerSubscription = combineLatest([manifestPromise, this.loader.load(loadKeyName)[0]]).subscribe(
				([manifest, sdkLoaded]) => {
					this.autoplay = autoplay;
					this.setupJWPlayer(manifest, this.video.videoId);
					this.jwState = ComponentLoadingState.LOADED;
					this.playerState.emit(this.jwState);

					// second try via jw manifest data - browser only
					if (this.jsonLd && Object.keys(this.jsonLd).length === 0) {
						this.jsonLd = this.getJsonLd(manifest);
					}
				},
				(error) => {
					this.jwState = ComponentLoadingState.ERROR;
					this.playerState.emit(this.jwState);
					console.warn("DflJwPlayerComponent.ngOnInit.load: couldn't load jw sdk.");
					console.error(error);
				}
			);
		}
	}

	ngOnDestroy() {
		this.destroyJWPlayer();
		if (this.manifestSubscription) {
			this.manifestSubscription.unsubscribe();
		}
		if (this.lStoreSubscription) {
			this.lStoreSubscription.unsubscribe();
		}
	}

	private destroyJWPlayer(): void {
		this.ngZone.runOutsideAngular(() => {
			if (this.jw) {
				this.jw.setup({
					related: {
						displayMode: 'shelf',
						autoplaytimer: 0,
						oncomplete: 'show'
					}
				});
				this.jw.stop();
				this.jw.remove();
				this.cdRef.detectChanges();
			}
		});
		if (this.jwPlayerSubscription) {
			this.jwPlayerSubscription.unsubscribe();
		}
	}

	/**
	 * Sets up jw player according to {video} and some global params.
	 * Should never be called directly.
	 */
	setupJWPlayer(manifest: JWManifest, videoId: string): void {
		this.ngZone.runOutsideAngular(() => {
			const labelsI19n = this.getI18NLabels();
			const adSchedule = this.getAdScheduleByLanguage();
			this.cdRef.detectChanges();
			this.jw = (<any>window).jwplayer(this.player.nativeElement);
			const jwRelatedPlaylist = this.jwPlaylistUrls[this.language] + videoId || manifest.playlist;
			const endcardBehaviour = this.autoplayNextVideo ? (this.playlist ? 'none' : 'autoplay') : 'show';
			/**
			 * see https://developer.jwplayer.com/jwplayer/docs/jw8-player-configuration-reference for details
			 */
			this.jw.setup({
				mediaId: videoId,
				playlist: this.playlist || manifest.playlist,
				playlistIndex: this.playlistIndex || 0,
				autostart: false,
				mute: this.mute,
				aspectRatio: '16:9',
				skin: {
					name: this.video.skinId
				},
				related: {
					displayMode: 'shelf',
					autoplaytimer: this.autoplaytimer,
					oncomplete: endcardBehaviour,
					file: jwRelatedPlaylist
				},
				labelsI19n,
				'advertising': this.ads === true ? adSchedule : [],
				autoPause: {
					viewability: true,
					pauseAds: false
				}
			});

			this.addPlayEventsToAnalytics();
			this.handleGeoblocking();
			this.unmuteOnFullscreenView();

			// start playing if player is ready
			this.jw.on('ready', () => {
				if (this.autoplay && this.jwState !== ComponentLoadingState.ERROR) {
					this.jw.play();
					if (this.fullscreen) {
						(this.jw as any).setFullscreen(true);
					}
				}
			});
			this.cdRef.detectChanges();
		});
	}

	/**
	 * get labels for endcards and related/next videos
	 */
	private getI18NLabels(): any {
		const labelNexUp = this.translate.get('jwPlayer.labels.nextUp');
		const labelAutoplay = this.translate.get('jwPlayer.labels.autoplay');
		const labelMoreVideos = this.translate.get('jwPlayer.labels.moreVideos');
		const languageCode = this.language === 'jp' ? 'ja' : this.language;
		const labelsI19n = {
			intl: {
				[languageCode]: {
					nextUp: labelNexUp || 'Next up',
					related: {
						autoplaymessage: labelAutoplay || '__title__ will play in xx seconds',
						heading: labelMoreVideos || 'More videos'
					}
				}
			}
		};
		return labelsI19n;
	}

	/**
	 * get Ad Schedule By Language
	 */
	private getAdScheduleByLanguage(): any {
		const languageCode = this.language === 'jp' ? 'ja' : this.language;
		const build = this.configService.getSettings('build', 'prod');
		const adConfig = build === 'dev' || build === 'localhost' ? this.jwPlaylistAdSchedulesDevelopment[languageCode][0] : this.jwPlaylistAdSchedules[languageCode][0];
		const adSchedule = {
			adscheduleid: Math.random().toString(36).substr(2, 8),
			client: 'vast',
			preloadAds: false,
			rules: {
				startOn: 1,
				frequency: 3,
				startOnSeek: 'pre',
				timeBetweenAds: 0
			},
			schedule: [
				{
					offset: 'pre',
					tag: adConfig['adTag'],
					type: 'linear'
				}
			],
			vpaidcontrols: false,
			vpaidmode: 'insecure'
		} as JWAdSchedule;
		return adSchedule;
	}

	/**
	 * Track play events
	 */
	public addPlayEventsToAnalytics(): void {
		const jwBaseEvent: PartialAnalyticsEvent = {
			eventName: 'interaction',
			category: 'Videos',
			label: this.video.videoId
		};

		// capture mediaId changes
		this.jw.on('playlistItem', (data) => {
			jwBaseEvent.label = data.item.mediaid;
			this.ngZone.run(() => {
				this.playlistIndexChange.emit(data.index);
				this.videoChangedEvent.emit(data.item);
			});
		});

		// Track ad events
		this.jw.on('adImpression', (data) => {
			this.analyticsService.eventTrack.next({ ...jwBaseEvent, action: 'Promo Banner Ad Started', label: `video ${jwBaseEvent.label}`, custom: { adTitle: data.adtitle }, category: 'Promo Banner APP' });
		});
		this.jw.on('adClick', () => {
			this.analyticsService.eventTrack.next({ ...jwBaseEvent, action: 'Promo Banner APP Click', label: `video ${jwBaseEvent.label}`, category: 'Promo Banner APP' });
		});

		// Track play events
		this.jw.on('play', () => {
			this.analyticsService.eventTrack.next(Object.assign({ action: 'play' }, jwBaseEvent));
		});

		this.jw.on('pause', () => {
			this.analyticsService.eventTrack.next(Object.assign({ action: 'pause' }, jwBaseEvent));
		});

		this.jw.on('mute', (data) => {
			this.analyticsService.eventTrack.next(Object.assign({ action: 'mute.' + data.mute.toString() }, jwBaseEvent));
		});

		this.jw.on('fullscreen', (data) => {
			this.analyticsService.eventTrack.next(Object.assign({ action: 'fullscreen.' + data.fullscreen.toString() }, jwBaseEvent));
		});

		this.jw.on('seek', (data) => {
			this.analyticsService.eventTrack.next(Object.assign({ action: 'seek.' + Math.round(data.position) }, jwBaseEvent));
		});
	}
	/**
	 * adds a error message to geo blocked videos
	 */
	public handleGeoblocking(): void {
		this.ngZone.runOutsideAngular(() => {
			this.jw.on('error', (evt) => {
				const jwGeoBlockedErrorCodes = [224003, 232403, 241403];

				this.jwState = ComponentLoadingState.ERROR;
				this.playerState.emit(this.jwState);
				let errorCategory = 'generic';
				if (evt.code && jwGeoBlockedErrorCodes.includes(evt.code)) {
					errorCategory = 'geo';
				}
				this.videoPosterStill = `/assets/jwplayer/${errorCategory}-${this.language}.jpg`;
				this.video.poster = `/assets/jwplayer/${errorCategory}-${this.language}.jpg`;
				this.destroyJWPlayer();
				this.autoplay = false;
				this.cdRef.detectChanges();
			});
		});
	}

	/**
	 * unmnutes the videoplayer if video is displayed in fullscreen
	 */
	public unmuteOnFullscreenView(): void {
		this.ngZone.runOutsideAngular(() => {
			this.jw.on('fullscreen', (data) => {
				if (data.type === 'fullscreen' && data.fullscreen === true && this.jw.getMute() === true) {
					this.jw.setMute(false);
				}
			});
		});
	}

	public nextTrack() {
		if (this.playlist.length > this.playlistIndex) {
			this.jw.playlistItem(this.playlistIndex + 1);
		}
	}
	public previousTrack() {
		if (this.playlistIndex > 0) {
			this.jw.playlistItem(this.playlistIndex - 1);
		}
	}

	/**
	 * Queries the JW API for all metadata for a given mediaID.
	 *
	 * @param {string} id
	 * @returns {Promise<JWManifest>}
	 */
	private getManifest(id: string): Observable<JWManifest> {
		const url = `https://cdn.jwplayer.com/v2/media/${id}?format=json`;
		return this.http.get<JWManifest>(url).pipe(
			share(),
			take(1),
			tap((manifest: JWManifest) => {
				this.manifest.emit(manifest);
			})
		);
	}

	/**
	 * Returns a object for ld+json script tags with as much data as possible
	 */
	private getJsonLd(manifest?: JWManifest): BundesligaJsonLdVideo | {} {
		let name,
			description,
			duration = '';
		let uploadDate: any;
		const currentUrl = this.configService.getSettings('system.applicationUrl', '') + this.router.routerState.snapshot.url;
		const embedUrl = 'https://cdn.jwplayer.com/previews/' + this.video.videoId;

		if (this.video && this.video.hasOwnProperty('headline') && this.video.headline !== '' && this.video.hasOwnProperty('description') && this.video.description !== '' && this.video.hasOwnProperty('published') && this.video.published > 0) {
			name = this.video.headline;
			description = this.video.description;

			uploadDate = getISO8601DateFromTimestamp(this.video.published);
			if (this.video.hasOwnProperty('duration') && this.video.duration > 0) {
				duration = getDurationISO8601(this.video.duration);
			}
		} else if (manifest && manifest.hasOwnProperty('playlist') && Object.keys(manifest.playlist[0]).length > 0) {
			const videoObject = manifest.playlist[0];
			name = videoObject['title'] || '';
			description = videoObject['description'] || '';
			uploadDate = getISO8601DateFromTimestamp(videoObject['pubdate']);
			duration = getDurationISO8601(videoObject['duration']);
		} else {
			return {};
		}

		const jsonLd = {
			'@context': 'https://schema.org',
			'@type': 'VideoObject',
			'name': name,
			'description': description,
			'thumbnailUrl': this.imageService.transform(this.videoPosterStill, 720),
			'uploadDate': uploadDate,
			'image': {
				'@type': 'ImageObject',
				'url': this.imageService.transform(this.videoPosterStill, 720)
			},
			'author': 'Bundesliga',
			'publisher': {
				'@type': 'Organization',
				'name': 'Bundesliga',
				'logo': {
					'@type': 'ImageObject',
					'url': 'https://s.bundesliga.com/images/favicons2017/ms-icon-310x310.png',
					'thumbnailUrl': 'https://s.bundesliga.com/images/favicons2017/ms-icon-310x310.png',
					'width': '310',
					'height': '310'
				}
			},
			'url': currentUrl,
			'duration': duration,
			'embedUrl': embedUrl
		} as BundesligaJsonLdVideo;
		return jsonLd;
	}
}
