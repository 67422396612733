import { Component, Input, OnInit } from '@angular/core';
import { BundesligaMatchStates, LiveBlogClubs, LiveBlogEntryEditorialEmbedSocials, LivetickerSides, Playtime } from '@nx-bundesliga/models';
import idx from 'idx';
import * as _moment from 'moment';
import { BehaviorSubject, of, timer } from 'rxjs';
import { debounce, mapTo } from 'rxjs/operators';

const moment = _moment;

@Component({
	selector: 'bl-youtube-event-input',
	templateUrl: './bl-youtube-event-input.component.html',
	styleUrls: ['./bl-youtube-event-input.component.scss', '../liveticker-events.common.scss']
})
export class BlYoutubeEventInputComponent implements OnInit {
	@Input() liveEntry: LiveBlogEntryEditorialEmbedSocials & { order?: any; disableCrosspost?: boolean };
	@Input() clubData: LiveBlogClubs;
	@Input() minuteOfPlay: Playtime;
	@Input() matchStatus: BundesligaMatchStates;
	@Input() language: string;
	@Input() competitionId: string;
	@Input() seasonId: string;
	@Input() matchdayId: string;
	@Input() matchId: string;
	@Input() headline: string;
	@Input() textBody: string;
	@Input() side: LivetickerSides;
	@Input() embedId: string;
	@Input() orderType = 'auto';
	@Input() orderValue = 'auto';
	@Input() editMode = false;
	@Input() customMatch = false;
	public previewEnabled$: any;
	public previewEntry: LiveBlogEntryEditorialEmbedSocials & { order?: any; disableCrosspost?: boolean };
	@Input() tributeOptions: any;
	constructor() {}

	public update() {
		this.previewEntry = Object.assign({}, this.liveEntry);
	}
	public updatePlaytime() {
		if (this.editMode === true && (this.liveEntry.playtime.minute !== this.minuteOfPlay.minute || this.liveEntry.playtime.injuryTime !== this.minuteOfPlay.injuryTime)) {
			if (this.orderType === 'keep') {
				this.orderType = 'auto';
			}
			this.updateOrderType();
		}
	}

	public updateOrderType() {
		switch (this.orderType) {
			case 'auto':
				this.orderValue = 'auto';
				break;
			case 'keep':
				this.orderValue = 'keep';
				break;
			case 'parent':
				this.orderValue = '';
				break;
			case 'child':
				this.orderValue = '';
				break;
			case 'number':
				this.orderValue = '';
				break;
			default:
				this.orderValue = '';
		}
		this.updateOrderValue();
	}

	public updateOrderValue() {
		let prependedText = '';
		if (this.orderType === 'parent' || this.orderType === 'child') {
			prependedText = this.orderType;
		}
		this.liveEntry.order = prependedText + this.orderValue;
	}

	ngOnInit() {
		this.liveEntry = {
			...this.liveEntry,
			entryType: 'embed',
			detail: {
				headline: this.headline || '',
				text: this.textBody || '',
				embedId: this.embedId ? this.embedId : idx(this.liveEntry, (_) => _.detail.embedId) || '', // YTuXm1UAb78
				embedPlatform: 'youtube'
			},
			eventId: '',
			entryDate: idx(this.liveEntry, (_) => _.entryDate) || '' + moment().format('YYYY-MM-DDTHH:mm:ssZZ'),
			matchSection: this.matchStatus || 'PRE_MATCH',
			side: this.side,
			playtime: {
				minute: this.minuteOfPlay.minute || 0,
				injuryTime: this.minuteOfPlay.injuryTime || 0
			},
			conference: this.customMatch === true ? false : idx(this.liveEntry, (_) => _.conference) || false,
			enableHtml: idx(this.liveEntry, (_) => _.enableHtml) || false,
			disableCrosspost: this.editMode || idx(this.liveEntry, (_) => _.disableCrosspost) || false,
			hidden: idx(this.liveEntry, (_) => _.hidden) || false
		};
		this.initializeOrderDefault();
		this.update();
		this.previewEnabled$ = new BehaviorSubject<boolean>(false);
		this.previewEnabled$ = this.previewEnabled$.pipe(debounce((val) => (val === false ? of(false) : timer(3000).pipe(mapTo(true)))));
		if (this.embedId || idx(this.previewEntry, (_) => _.detail.embedId) || false) {
			this.reloadEmbed();
		}
	}

	initializeOrderDefault() {
		if (this.editMode === true) {
			this.orderType = 'keep';
			this.updateOrderType();
		}
	}

	public getPayload() {
		return this.liveEntry;
	}

	public reloadEmbed() {
		this.previewEnabled$.next(false);
		this.previewEnabled$.next(true);
	}
}
