import { NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';
import { EmbedApesterComponent } from './components/embed-apester/embed-apester.component';
import { EmbedFacebookComponent } from './components/embed-facebook/embed-facebook.component';
import { EmbedInstagramComponent } from './components/embed-instagram/embed-instagram.component';
import { EmbedMonterosaComponent } from './components/embed-monterosa/embed-monterosa.component';
import { EmbedTweetComponent } from './components/embed-tweet/embed-tweet.component';
import { EmbedWrapperComponent } from './components/embed-wrapper/embed-wrapper.component';

@NgModule({
	imports: [SharedModule, CommonsModule, EmbedMonterosaComponent, NgOptimizedImage],
	providers: [],
	exports: [EmbedWrapperComponent, EmbedApesterComponent],
	declarations: [EmbedTweetComponent, EmbedWrapperComponent, EmbedInstagramComponent, EmbedApesterComponent, EmbedFacebookComponent]
})
export class DflNgxEmbedsModule {}
