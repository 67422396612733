import { CommonModule, isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, computed, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { configure } from '@monterosa-sdk/core';
import { getIdentify, onCredentialsValidationFailed, onLoginRequestedByExperience, setCredentials } from '@monterosa-sdk/identify-kit';
import { embed, Experience, getExperience, onReady } from '@monterosa-sdk/launcher-kit';
import { TranslateModule } from '@ngx-translate/core';
import { BundesligaEmbedMonterosa } from '@nx-bundesliga/models';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { combineLatestWith, distinctUntilChanged, ReplaySubject, Subscription, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

/**
 * Embed Monterosa Component
 * Expected Output: BundesligaEmbedMonterosa
 * Description:
 * 		This component is used to embed Monterosa experience in the page.
 * 		It takes BundesligaEmbedMonterosa as input and renders the Monterosa experience in the page.
 * 			1. If the channelsEnabled.mobile is true and isWebview is true, then it will render the Monterosa experience in the webview.
 * 			2. If the channelsEnabled.web is true and isWebview is false, then it will render the Monterosa experience in the web.
 * 			3. If the channelsEnabled.mobile and channelsEnabled.web are false, then it will not render the Monterosa experience.
 * 			4. If the eventId and projectId are not provided, then it will not render the Monterosa experience.
 * 			5. If the oktaWebViewAccessToken is provided, then it will set the credentials for the Monterosa experience.
 * Example URL:
 * 		http://localhost:4000/de/liveticker/DFL-COM-000001/DFL-SEA-0001K8/DFL-DAY-004C69/DFL-MAT-J04034
 *
 * How to get an example token:
 * 		1. Open the browser console from this url:
 * 			'https://bundesliga-dev.com'
 * 		2. Login to your account
 * 		3. Run the following command in the console:
 * 			JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken
 * 		4. Copy the token and use it in the oktaWebViewAccessToken input
 */

@Component({
	selector: 'bundesliga-embed-monterosa',
	standalone: true,
	templateUrl: './embed-monterosa.component.html',
	styleUrls: ['./embed-monterosa.component.scss'],
	imports: [CommonModule, NgOptimizedImage, TranslateModule],
	changeDetection: ChangeDetectionStrategy.Default
})
export class EmbedMonterosaComponent implements OnDestroy, AfterViewInit, OnInit {
	@Input() set embed(value: BundesligaEmbedMonterosa) {
		this.monterosa = value;
		this.showMonterosa = this.monterosa && ((this.monterosa?.channelsEnabled?.mobile && this.isWebview) || (this.monterosa?.channelsEnabled?.web && !this.isWebview));
		this.eventId$.next(value?.eventId);
		this.projectId$.next(value?.projectId || this.config.getSettings('monterosa.projectId', ''));
	}
	@Input() language = 'en';

	private experience: Experience;
	public eventId$: ReplaySubject<string> = new ReplaySubject<string>(1);
	public projectId$: ReplaySubject<string> = new ReplaySubject<string>(1);
	public isBrowser = false;
	public embedSubscription: Subscription;
	public readonly instanceId = uuidv4();
	public monterosa: BundesligaEmbedMonterosa;
	public showMonterosa = true;
	public isWebview = false;
	private readonly oktaWebViewAccessToken = signal<string | null>(null);
	public readonly isAuthenticated = computed(() => this.oktaWebViewAccessToken());

	constructor(@Inject(PLATFORM_ID) private readonly platformId: Object, private config: ConfigService) {
		this.isBrowser = isPlatformBrowser(this.platformId);
		this.isWebview = this.config.getSettings('webview', false);
	}

	ngOnInit(): void {
		//set oktaWebViewAccessToken in window
		//window['oktaWebViewAccessToken'] =
		//	'eyJraWQiOiItUk0yV2Q0NEJNY3ZGaFJibVNJUVBvYzMwMC03Z1B6NDBPNnVxalhKRUFjIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULmpfSHNaQXo4aHpjbDdkRmUyNkY0NlJudklaZFh2c0c2SldzMEUyYUMwamMub2FyOG50Z29pb1czWEVMbXgweDYiLCJpc3MiOiJodHRwczovL2J1bmRlc2xpZ2Eub2t0YXByZXZpZXcuY29tL29hdXRoMi9hdXMxZHYxbWNiV1FWSmJEZTB4NyIsImF1ZCI6ImJ1bmRlc2xpZ2EtZGV2LmNvbSIsImlhdCI6MTczNTgxNDE2MCwiZXhwIjoxNzM1ODE0NzAwLCJjaWQiOiIwb2ExZWtweGRqbER2V2NYbjB4NyIsInVpZCI6IjAwdTh4M3pydWtwbURla29VMHg3Iiwic2NwIjpbIm9mZmxpbmVfYWNjZXNzIiwicHJvZmlsZSIsImVtYWlsIiwib3BlbmlkIl0sImF1dGhfdGltZSI6MTczNTgxMTEwNiwic3ViIjoiZXh0LnNpbmtzYXIudm9uLnF1aWxsZmVsZHRAZGZsbmV0LmRlIiwibGFzdE5hbWUiOiJ2b24gUXVpbGxmZWxkdC1HaGVicmVtZWRoaW4iLCJERkxfZmFudGFzeV9ndWVzdFVzZXJJZCI6IiIsIkRGTF9jcm9zc1Byb2R1Y3RQcm9maWxlQnVpbGRpbmdPcHRvdXQiOmZhbHNlLCJERkxfcmVnaXN0cmF0aW9uQ29tcGxldGUiOnRydWUsIkRGTF9mYXZvcml0ZVRlYW1zIjpbIkRGTC1DTFUtMDAwMDA3Il0sImxvZ2luIjoiZXh0LnNpbmtzYXIudm9uLnF1aWxsZmVsZHRAZGZsbmV0LmRlIiwiREZMX2JpcnRoZGF0ZSI6IjE5OTEtMDEtMDQiLCJmaXJzdE5hbWUiOiJTaW5rc2FyIiwiREZMX2dlbmRlciI6Im5vbmUiLCJERkxfaXNCdW5kZXNsaWdhUGx1c0ludGVybmFsIjpmYWxzZSwiREZMX2xlZ2FjeVVzZXJJZEdpZ3lhIjoiIiwiY291bnRyeUNvZGUiOiJERSIsIkRGTF9pc1ZlcmlmaWVkIjp0cnVlLCJzb2NpYWxQcm92aWRlciI6WyJhcHBfc29jaWFsX2xvZ2luc19nb29nbGUiLCJhcHBfc29jaWFsX2FsbCIsImFwcF9zb2NpYWxfbG9naW5zX2dvb2dsZV92ZXJpZmllZCIsImFwcF9zb2NpYWxfYWxsX3ZlcmlmaWVkIl0sImVtYWlsIjoiZXh0LnNpbmtzYXIudm9uLnF1aWxsZmVsZHRAZGZsbmV0LmRlIiwiREZMX2ZhbnRhc3lfdGVhbU5hbWUiOiIifQ.Iz4DkrXG31uHgz2fPFFrN9hXQi6yeRoOYr_-M0RRkxjDJmOEqMYlrHjiP4dr9hIxuXykSqislfqy0y0V2uijMt8HfijAQksOEA-IltYL1vn817uRwh5YZNC4xwRsQGTH1myjv7Ebd65NmZfCk0kJ3hTIJTv-cjtlpGxhz2ZuiWlelXhdszVF-gYRjL0nuHwe_wSe1u5maQ5ypSh3s0pv0AmzkPgPeS2YCyH2zWtLoFKWYUhvBbGPWIhBLRizf7y3vAWGGPsNXN2xzRxzuA7KxsJ8VMeuqhw2xvuKMuxFGBNaA3PW5_EgDIq1m6ASbyB_HwC8W5w_382UljBLf0PhhQ';
		this.setOktaWebViewAccessToken();
	}

	async ngAfterViewInit(): Promise<void> {
		if (this.isBrowser) {
			this.embedSubscription = this.eventId$
				.pipe(
					distinctUntilChanged(),
					combineLatestWith(this.projectId$.pipe(distinctUntilChanged())),
					tap(([eventId, projectId]: [string, string]) => {
						if (this.experience) {
							this.experience.bridge.removeAllListeners();
						}
						if (this.showMonterosa) {
							configure({
								host: 'cdn.monterosa.cloud',
								projectId: projectId
							});
							this.experience = getExperience({
								eventId: eventId,
								autoresizesHeight: true,
								parameters: { lang: this.language }
							});

							embed(this.experience, this.instanceId);
						}
					})
				)
				.subscribe(([eventId, projectId]) => {
					const identify = getIdentify();
					const token = this.oktaWebViewAccessToken();

					onReady(this.experience, async () => {
						if (token) {
							setCredentials(identify, { token: token });
						}
					});
					onCredentialsValidationFailed(identify, (error) => {
						console.error('Monterosa Credentials validation failed', error);
					});

					onLoginRequestedByExperience(identify, async () => {
						this.openSignUpScreen();
					});
				});
		}
	}

	/**
	 * Open sign up screen
	 * @returns void
	 */
	openSignUpScreen(): void {
		if ((<any>window).dflWebViewEvent) {
			// Call Android interface
			(<any>window).dflWebviewEvent.logEvent(
				'openSignUpScreen',
				JSON.stringify({
					webviewUrl: window.location.origin + window.location.pathname + window.location.search
				})
			);
		} else if ((<any>window).webkit && (<any>window).webkit.messageHandlers && (<any>window).webkit.messageHandlers.dflWebviewEvent) {
			// Call iOS interface
			const message = {
				name: 'openSignUpScreen',
				parameters: {}
			};
			(<any>window).webkit.messageHandlers.dflWebviewEvent.postMessage(message);
		} else {
			console.warn('No interface found');
		}
	}

	// get injected access token
	private setOktaWebViewAccessToken() {
		if (typeof window !== 'undefined' && (window as any).oktaWebViewAccessToken) {
			this.oktaWebViewAccessToken.set((window as any).oktaWebViewAccessToken);
		} else {
			this.oktaWebViewAccessToken.set(null);
		}
	}

	ngOnDestroy() {
		// do we need to unsubscribe the experience or can we destroy it
		this.experience?.bridge?.removeAllListeners();
		this.embedSubscription?.unsubscribe();
	}
}
