<mat-dialog-content class="mat-typography">
	<div class="container">
		<div class="row">
			<div class="col-1 col-md-3 col-lg-4" *ngFor="let event of (monterosaEvents$ | async)?.events">
				<div class="monterosaEventCard" (click)="experienceSelected(event.id)">
					<p><strong>{{event.name}}</strong></p>
					<p><em>{{event.start_at_iso | date:'dd.MM.yyyy HH:mm'}} - {{event.end_at_iso | date:'dd.MM.yyyy HH:mm'}}</em></p>
					<p>
						Started:
						<mat-icon *ngIf="event.started">check_circle</mat-icon>
						<mat-icon *ngIf="!event.started">error</mat-icon>
					</p>
					<p><small>{{event.id}}</small></p>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button mat-button [matDialogClose]="null">Close</button>
</mat-dialog-actions>
