import { Location, LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeJa from '@angular/common/locales/ja';
import localeFr from '@angular/common/locales/fr';
import localeAr from '@angular/common/locales/ar';
import localePt from '@angular/common/locales/pt';
import { APP_INITIALIZER, Injector, LOCALE_ID, ModuleWithProviders, NgModule, Optional, SkipSelf, TransferState } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateBrowserLoader } from './translate-browser-loader.service';
import { take } from 'rxjs/operators';
import { I18NService } from './i18n.service';
import { environment } from '@nx-bundesliga/bundesliga-com/environment';

// used as described here: https://github.com/ngx-translate/core/issues/517#issuecomment-299637956
export function appInitializerLanguageLoaderFactory(translate: TranslateService, location: Location, injector: Injector) {
	return () =>
		new Promise<any>((resolve: any) => {
			const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
			locationInitialized.then(() => {
				registerLocaleData(localeEn, 'en');
				registerLocaleData(localeDe, 'de');
				registerLocaleData(localeJa, 'ja');
				registerLocaleData(localeEs, 'es');
				registerLocaleData(localeFr, 'fr');
				registerLocaleData(localeAr, 'ar');
				registerLocaleData(localePt, 'pt');
				const queryParamSplit = location.path().split('?');
				let langToSet = 'en';
				let pathSlices: string[] = [];
				const availableLanguages = environment.i18n.availableLanguages.map((item) => item.code); // @todo @fixme use values from config and not web enviroment
				if (queryParamSplit.length > 0) {
					pathSlices = queryParamSplit[0].split('/');
				}
				if (pathSlices.length > 1 && availableLanguages.includes(pathSlices[1])) {
					langToSet = pathSlices[1];
				}
				translate.setDefaultLang(langToSet);
				translate
					.use(langToSet)
					.pipe(take(1))
					.subscribe(
						() => {
							// console.info(`Successfully initialized '${langToSet}' language.'`);
						},
						(err) => {
							console.error(`Problem with '${langToSet}' language initialization.'`);
						},
						() => {
							resolve(null);
						}
					);
			});
		});
}
export function factoryTranslateLoader(http: HttpClient, transferState: TransferState) {
	return new TranslateBrowserLoader('/assets/i18n/', '.json', transferState, http);
	// return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	imports: [TranslateModule.forRoot()],
	declarations: [],
	providers: [
		I18NService,
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerLanguageLoaderFactory,
			deps: [TranslateService, Location, Injector],
			multi: true
		},
		{
			provide: LOCALE_ID,
			useFactory: (translate: TranslateService) => {
				return translate.currentLang === 'jp' ? 'ja' : translate.currentLang || 'en';
			},
			deps: [TranslateService]
		},
		{
			provide: MAT_DATE_LOCALE,
			useFactory: (translate: TranslateService) => {
				return translate.currentLang === 'jp' ? 'ja' : translate.currentLang || 'en';
			},
			deps: [TranslateService]
		}
	],
	exports: [TranslateModule]
})
export class I18NModule {
	static forRoot(configuredProviders?: Array<any>): ModuleWithProviders<I18NModule> {
		return {
			ngModule: I18NModule,
			providers: configuredProviders
		};
	}

	constructor(@Optional() @SkipSelf() parentModule: I18NModule) {
		if (parentModule) {
			throw new Error('I18NModule already loaded. Import in root module only.');
		}
	}
}
