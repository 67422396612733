// module
import { isPlatformBrowser } from '@angular/common';
import { ClassProvider, FactoryProvider, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { Window } from './window';

export class UniversalWindow implements Window {
	public navigator: any = {};
	public location: any = {};
	public document: any = {};

	// google tag manager stub for web
	public dataLayer: Array<any> = [];

	public googletag: any = {};
	// IOS and ANDROID webview intgration
	public AnalyticsWebInterface: any = null;
	public webkit: any = null;

	public alert(msg: string) {
		return;
	}
	public confirm(msg: string) {
		return;
	}
	public btoa(msg: string): string {
		return null;
	}
	public scrollTo(a: number, b: number) {
		return null;
	}
	public open(...args: Array<any>): any {
		return null;
	}
	public setTimeout(handler: (...args: any[]) => void, timeout?: number): number {
		return 0;
	}
	public clearTimeout(timeoutId: number): void {}
	public setInterval(handler: (...args: any[]) => void, ms?: number, ...args: any[]): number {
		return 0;
	}
	public clearInterval(intervalId: number): void {}

	// google analytics stub for web
	public ga(command: string | Function, params?: any, extra?: any): void {}
}

/* Create a new injection token for injecting the window into a component. */
export const WINDOW = new InjectionToken('WindowToken');

/* Define abstract class for obtaining reference to the global window object. */
export abstract class WindowRef {
	get nativeWindow(): Window | Object {
		throw new Error('Not implemented.');
	}
}

/* Define class that implements the abstract class and returns the native window object. */
@Injectable()
export class BrowserWindowRef extends WindowRef {
	constructor() {
		super();
	}

	get nativeWindow(): Window | Object {
		return window;
	}
}

/* Create an factory function that returns the native window object. */
export function windowFactory(browserWindowRef: BrowserWindowRef, platformId: Object): Window | Object {
	if (isPlatformBrowser(platformId)) {
		return browserWindowRef.nativeWindow;
	}
	return new UniversalWindow();
}

/* Create a injectable provider for the WindowRef token that uses the BrowserWindowRef class. */
const browserWindowProvider: ClassProvider = {
	provide: WindowRef,
	useClass: BrowserWindowRef
};

/* Create an injectable provider that uses the windowFactory function for returning the native window object. */
const windowProvider: FactoryProvider = {
	provide: WINDOW,
	useFactory: windowFactory,
	deps: [WindowRef, PLATFORM_ID]
};

/* Create an array of providers. */
export const WINDOW_PROVIDERS = [browserWindowProvider, windowProvider];
