<mat-dialog-content>
	<h2>Edit CTA</h2>
	<div [formGroup]="form" *ngIf="form">
		<!-- Target -->
		<mat-form-field class="w-100" appearance="outline">
			<mat-label>CTA Target</mat-label>
			<mat-select formControlName="target" (selectionChange)="changeTarget($event)">
				<mat-option value="">none</mat-option>
				<mat-option *ngFor="let t of ctaTypes" [value]="t">{{ t }}</mat-option>
			</mat-select>
		</mat-form-field>

		<!-- Text -->
		<mat-form-field class="w-100" appearance="outline">
			<mat-label>Text</mat-label>
			<input matInput type="text" formControlName="text" />
		</mat-form-field>

		<ng-container formGroupName="targetParams">
			<nx-bundesliga-bundesmaster-ui-competition-selector *ngIf="getTargetParamsControls()?.['competitionId']" [formControl]="getTargetParamsControls()?.['competitionId']" providedValue="id" />

			<nx-bundesliga-bundesmaster-ui-season-selector *ngIf="getTargetParamsControls()?.['seasonId']" [formControl]="getTargetParamsControls()?.['seasonId']" providedValue="seasonId" />

			<nx-bundesliga-bundesmaster-ui-matchday-selector *ngIf="getTargetParamsControls()?.['matchdayId']" [formControl]="getTargetParamsControls()?.['matchdayId']" [seasonIdFilter]="getTargetParamsControls()?.['seasonId']?.value" />

			<nx-bundesliga-bundesmaster-ui-match-selector
				*ngIf="getTargetParamsControls()?.['matchId']"
				[formControl]="getTargetParamsControls()?.['matchId']"
				[competitionIdFilter]="getTargetParamsControls()?.['competitionId']?.value"
				[seasonIdFilter]="getTargetParamsControls()?.['seasonId']?.value"
				[matchdayIdFilter]="getTargetParamsControls()?.['matchdayId']?.value" />

			<nx-bundesliga-bundesmaster-ui-club-selector *ngIf="getTargetParamsControls()?.['clubId']" [formControl]="getTargetParamsControls()?.['clubId']" [competitionIdFilter]="getTargetParamsControls()?.['competitionId']?.value" [seasonIdFilter]="getTargetParamsControls()?.['seasonId']?.value" />

			<nx-bundesliga-bundesmaster-ui-person-selector *ngIf="getTargetParamsControls()?.['personId']" [formControl]="getTargetParamsControls()?.['personId']" [seasonIdFilter]="getTargetParamsControls()?.['seasonId']?.value" [clubIdFilter]="getTargetParamsControls()?.['clubId']?.value" />

			<!-- Tab -->
			<mat-form-field *ngIf="getTargetParamsControls()?.['tab']" class="w-100" appearance="outline">
				<mat-label>Tab</mat-label>
				<mat-select [formControl]="getTargetParamsControls()?.['tab']">
					<mat-option value="liveticker">liveticker</mat-option>
					<mat-option value="stats">stats</mat-option>
				</mat-select>
			</mat-form-field>

			<!-- ArticleId -->
			<mat-form-field *ngIf="getTargetParamsControls()?.['articleId']" class="w-100" appearance="outline">
				<mat-label>ArticleID</mat-label>
				<input matInput type="text" [formControl]="getTargetParamsControls()?.['articleId']" />
			</mat-form-field>

			<!-- Monterosa -->
			<mat-form-field *ngIf="getTargetParamsControls()?.['eventId']" class="w-100" appearance="outline">
				<mat-label>EventID</mat-label>
				<input matInput type="text" [formControl]="getTargetParamsControls()?.['eventId']" />
			</mat-form-field>
			<mat-form-field *ngIf="getTargetParamsControls()?.['projectId']" class="w-100" appearance="outline">
				<mat-label>ProjectID</mat-label>
				<input matInput type="text" [formControl]="getTargetParamsControls()?.['projectId']" />
			</mat-form-field>
			<div class="container" *ngIf="form?.controls?.['target']?.value === 'monterosa'">
				<button type="button" color="primary" aria-label="Select Monterosa" title="Select Monterosa" mat-flat-button (click)="openMonterosaDialog()">
					Select Monterosa
					<mat-icon aria-label="Select Monterosa">public</mat-icon>
				</button>
			</div>
		</ng-container>
	</div>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button mat-flat-button color="warn" (click)="discard()">Discard</button>
	<button mat-flat-button color="primary" [disabled]="!form.valid" (click)="save()">Save</button>
</mat-dialog-actions>
